// Helpers
import moment from 'moment';

// Render element or component by provided condition
export function renderIf(condition, renderFn) {
  return condition ? renderFn() : null
}

// Substring with ...
export function subString(string, length = 0) {
  return string.length > length ? `${string.substr(0, length)}...` : string
}

export function dateFormatter(dateStr) {
  //  Convert a string like '2020-10-04T00:00:00' into '4/Oct/2020'
  //let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  if (dateStr == "0000-00-00 00:00:00" || dateStr == null) {
    return "N/A";
  }
  // check if valid date
  if (isNaN(Date.parse(dateStr))) {
    return "Invalid Date";
  }
  let date = new Date(dateStr);
  let str = date.toISOString().split('T')[0];
  let formattedDate = moment(str).format('LL');

  return formattedDate;
}

export function dateTime(date) {
  return moment(date).format("LLLL");
}

export function dateTimeMediumLength(date) {
  return moment(date).format("LLL");
}

export function txtBoxDateFormatter(dateStr) {
  var d = new Date(dateStr),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  return [year, month, day].join('-');
}

export function getCurrentDay_MonthDay() {
  //  Convert a string like '2020-10-04T00:00:00' into '4/Oct/2020'
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let currentdate = new Date();
  let toReturn = months[currentdate.getMonth()] + ' ' + currentdate.getDate();
  return toReturn;
}

export function dueDateSortFunction(a, b) {
  var dateA = new Date(a.dueDate.toString()).getTime();
  var dateB = new Date(b.dueDate.toString()).getTime();
  return dateA > dateB ? 1 : -1;
};

// This helper method returns true or false if the total is negative. Used to filter bills at checkout
export function billIsStillValid(bill) {
  return (bill.total - bill.paymentsCredits) > 0
}

// This helper method returns true or false if the total is negative. Used to filter bills at checkout
export function getBillValue(bill) {
  const result = bill.total - bill.paymentsCredits;
  if (result > 0) {
    return result;
  }
  return 0;
}

/*
// Another helper method to centralize totaling processes that are at this time uncertain
export function getBalanceDueSum(bills){
  var curentBalance = 0.00;
  if (this.props.bills.data.length > 1)
  {
      this.props.bills.data.sort(dueDateSortFunction);
      if (this.props.bills.data[0].usage < this.props.bills.data[1].usage){
          improvingConsumption = true;
      }
  }
  return 0;
}

// Another helper method to centralize totaling processes that are at this time uncertain
export function getCurrentAvailableCreditsSum(bill){
  const result = bill.total - bill.paymentsCredits;
    if (result > 0)
    {
      return result;
    }
    return 0;
}*/

// Expects a string like  '4/10/2020'
export function isLate(dateStr) {
  dateStr = dateStr.split("/");
  var todayDate = new Date(),
    //endDate = new Date( todayDate.getFullYear(), todayDate.getMonth() + 6, todayDate.getDate() +1 );
    date = new Date(dateStr[2], dateStr[0] - 1, dateStr[1]);
  return todayDate > date;
}

// Expects a string like  '4/10/2020'
export function isDueIn5Days(dateStr) {
  var inputDate = new Date(dateStr.split('/')[2], dateStr.split('/')[1] - 1, dateStr.split('/')[0]);
  var endDate = new Date();
  var todayDate = new Date();
  endDate.setDate(endDate.getDate() + 5);// adding 5 days from today
  return (inputDate < endDate && inputDate > todayDate);
}


export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    if(amount == 0 && decimalCount == 2 && decimal == "."){
      return '$0.00';
    }
    const negativeSign = amount < 0 ? "-" : "";
    if(amount == "")
      return;
    let newAmount = Number(amount).toFixed(2);
    amount = Math.ceil(newAmount * 100) / 100;

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return "$" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
}

// Duplicate object
export function duplicate(object) {
  return Object.assign({}, object)
}

// Return empty string if value is null
export function nullToEmptyString(value) {
  return value === null ? '' : value
}

// Return zero if value is null
export function nullToZero(value) {
  return value === null ? 0 : value
}

// Add (s) to any string by count
export function plural(value) {
  return value === 1 ? '' : 's'
}

// Check if object is empty
export function isEmpty(obj) {
  let name;
  for (name in obj) {
    if (obj.hasOwnProperty(name)) {
      return false;
    }
  }
  if (obj.constructor !== Object) {
    return false;
  }
  return true;
}

export function sanitizeText(text) {
  return text.toString()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    //.replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '')            // Trim - from end of text
}

// Slug
export function slug(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    //.replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '')            // Trim - from end of text
}
