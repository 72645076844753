// Imports
import axios from 'axios'
import cookie from 'js-cookie'

// App Imports
require('../../../setup/config/apiConfig');

// Actions Types
export const SET_PAYMENT_METHOD_LIST = 'PAYMENT_METHOD/SET_PAYMENT_LIST'
export const PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_REQUEST = 'PAYMENT_METHOD/PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST'
export const PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_RESPONSE = 'PAYMENT_METHOD/PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE'

export const PAYMENT_METHOD_UPDATE_REQUEST = 'PAYMENT_METHOD/PAYMENT_METHOD_UPDATE_REQUEST'
export const PAYMENT_METHOD_UPDATE_RESPONSE = 'PAYMENT_METHOD/PAYMENT_METHOD_UPDATE_RESPONSE'
export const PAYMENT_METHOD_UPDATE_FAILURE = 'PAYMENT_METHOD/PAYMENT_METHOD_UPDATE_FAILURE'

export const PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_FAILURE = 'PAYMENT_METHOD/PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE'
export const PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_RESET = 'PAYMENT_METHOD/PAYMENT_GET_ACCOUNT_PAYMENTS_RESET'

export const CREATE_AUTO_PAYMENTS_REQUEST = 'CREATE_AUTO_PAYMENTS_REQUEST'
export const CREATE_AUTO_PAYMENTS_RESPONSE = 'CREATE_AUTO_PAYMENTS_RESPONSE'
export const CREATE_AUTO_PAYMENTS_FAILURE = 'CREATE_AUTO_PAYMENTS_FAILURE'

export const UPDATE_AUTO_PAYMENTS_REQUEST = 'UPDATE_AUTO_PAYMENTS_REQUEST'
export const UPDATE_AUTO_PAYMENTS_RESPONSE = 'UPDATE_AUTO_PAYMENTS_RESPONSE'
export const UPDATE_AUTO_PAYMENTS_FAILURE = 'UPDATE_AUTO_PAYMENTS_FAILURE'

export const GET_CC_PAYMENT_PROFILE_REQUEST = 'GET_CC_PAYMENT_PROFILE_REQUEST'
export const GET_CC_PAYMENT_PROFILE_RESPONSE = 'GET_CC_PAYMENT_PROFILE_RESPONSE'
export const GET_CC_PAYMENT_PROFILE_FAILURE = 'GET_CC_PAYMENT_PROFILE_FAILURE'

export const GET_ACH_PAYMENT_PROFILE_REQUEST = 'GET_ACH_PAYMENT_PROFILE_REQUEST'
export const GET_ACH_PAYMENT_PROFILE_RESPONSE = 'GET_ACH_PAYMENT_PROFILE_RESPONSE'
export const GET_ACH_PAYMENT_PROFILE_FAILURE = 'GET_ACH_PAYMENT_PROFILE_FAILURE'

export const UPDATE_PAYMENT_PROFILE_REQUEST = 'UPDATE_PAYMENT_PROFILE_REQUEST'
export const UPDATE_PAYMENT_PROFILE_RESPONSE = 'UPDATE_PAYMENT_PROFILE_RESPONSE'
export const UPDATE_PAYMENT_PROFILE_FAILURE = 'UPDATE_PAYMENT_PROFILE_FAILURE'

export const RESET_BANK_PAYMENT_OBJECT = 'RESET_BANK_PAYMENT_OBJECT'
export const RESET_CARD_PAYMENT_OBJECT = 'RESET_CARD_PAYMENT_OBJECT'




// Actions

export function setCurrentUserPaymentMethods(data) {
  return { type: SET_PAYMENT_METHOD_LIST, data }
}
export function resetBankPaymentMethod() {
  return dispatch => {
    dispatch({
      type: RESET_BANK_PAYMENT_OBJECT
    })
  }
}
export function resetCardPaymentMethod() {
  return dispatch => {
    dispatch({
      type: RESET_CARD_PAYMENT_OBJECT
    })
  }
}

// Get all account's saved payment method previews from the server
export function getAccountPaymentMethodPreviews(userId, isLoading = true) {
  return dispatch => {
    dispatch({
      type: PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_REQUEST,
      isLoading: isLoading
    });
    return axios.get('/savedpaymentmethods/user/' + userId)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {
          //Updating the bills obj in state
          dispatch({
            type: PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_RESPONSE,
            isLoading: false,
            error: error,
            data: response.data
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!"
          })
        }
      })
      .catch(error => {
        dispatch({
          type: PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_RESPONSE,
          isLoading: false,
          error: error
        })
      })
  }
}

export function createNewAutoPay(autoPaySubmissionPayload) {
  return (dispatch) => {
    dispatch({
      type: CREATE_AUTO_PAYMENTS_REQUEST,
      isLoading: true,
    });
    return axios.post("/autopay", autoPaySubmissionPayload)
    .then((response) => {
      // Update/Check if user object is the obj state
      let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem("user"));
        if (updatedUser) {
          dispatch({
            type: CREATE_AUTO_PAYMENTS_RESPONSE,
            isLoading: false,
            newAutoPay: response?.data[0],
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: CREATE_AUTO_PAYMENTS_FAILURE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
    })
      .catch((error) => {
        dispatch({
          type: CREATE_AUTO_PAYMENTS_FAILURE,
          isLoading: false,
          error: error.response.data.message,
        });
      });
  };
}

export function updateAutoPayments(autoPayments) {
  let toSend = autoPayments;
  if (!Array.isArray(toSend)) {
    toSend = [autoPayments];
  }
  return dispatch => {
    dispatch({
      type: UPDATE_AUTO_PAYMENTS_REQUEST,
      error: null,
      isLoading: true
    })
    return axios.post(
      "/update-autopayments",
      { autoPayments: toSend }
    ). then( response => {
      let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem("user"));
        if (updatedUser) {
          dispatch({
            type: UPDATE_AUTO_PAYMENTS_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: UPDATE_AUTO_PAYMENTS_FAILURE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
    }).catch((error) => {
      dispatch({
        type: UPDATE_AUTO_PAYMENTS_FAILURE,
        isLoading: false,
        error: error.response.data.message,
      });
    });
  }
}

export function addNewAccountCreditCard(newCC) {
  return dispatch => {
    dispatch({
      type: PAYMENT_METHOD_UPDATE_REQUEST,
      error: null,
      isLoading: true
    })
    return axios.post("/creditcard", newCC).then(response => {
      let error = '';
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }else if(response.data.error){
        error = response.data.error ? response.data.AuthNetResult?.message : response.data.error;
      }
      dispatch({
        type: PAYMENT_METHOD_UPDATE_RESPONSE,
        error: error
      })
    }).catch(err => {
      let error = ''
      if (err.response?.data?.message) {
        error = err.response?.data?.message;
      }
      dispatch({
        type:PAYMENT_METHOD_UPDATE_FAILURE,
        error: error
      })
    });
  }
}

export function addNewAccountACH(newACH) {
  return dispatch => {
    dispatch({
      type: PAYMENT_METHOD_UPDATE_REQUEST,
      error: null,
      isLoading: true
    })
    return axios.post("/ach", newACH).then(response => {
      let error = '';
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }else if(response.data.error){
        error = response.data.error ? response.data.AuthNetResult?.message : response.data.error;
      }
      dispatch({
        type: PAYMENT_METHOD_UPDATE_RESPONSE,
        error: error
      })
    }).catch(err => {
      let error = ''
      if (err.response?.data?.message) {
        error = err.response?.data?.message;
      }
      dispatch({
        type:PAYMENT_METHOD_UPDATE_FAILURE,
        error: error
      })
    });
  }
}


export function deleteAccountPaymentMethod(uuid, type) {
  return dispatch => {
    dispatch({
      type: PAYMENT_METHOD_UPDATE_REQUEST,
      error: null,
      isLoading: true
    })
    if (type == "ach") {
      return axios.delete("/ach/" + uuid).then(response => {
        let error = '';
        dispatch({
          type: PAYMENT_METHOD_UPDATE_RESPONSE,
          error: error
        })
      });
    }
    else // Defaults cc
    {
      return axios.delete("/creditcard/" + uuid).then(response => {
        let error = '';
        dispatch({
          type: PAYMENT_METHOD_UPDATE_RESPONSE,
          error: error
        })
      });
    }
  }
}

export function getPaymentProfileCC(authorizeProfileId) {
  const ids = authorizeProfileId.split("/");
  const customerProfileId = ids[0];
  const paymentProfileId = ids[1];
  return dispatch => {
    dispatch({
      type: GET_CC_PAYMENT_PROFILE_REQUEST,
      error: null,
      isLoading: true
    })
    return axios.get(`/paymentProfileCC/${customerProfileId}/${paymentProfileId}`).then(response => {
      let error = '';
      
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }else if(response.data.error){
        error = response.data.error ? response.data.AuthNetResult?.message : response.data.error;
      }
      dispatch({
        type: GET_CC_PAYMENT_PROFILE_RESPONSE,
        data: response?.data?.profile,
        error: error
      })
    }).catch(err => {
      let error = ''
      
      if (err.response?.data?.message) {
        error = err.response?.data?.message;
      }else {
        error = "Error getting payment profile"
      }
      
      dispatch({
        type:GET_CC_PAYMENT_PROFILE_FAILURE,
        error: error
      })
    });
  }
}
export function getPaymentProfileACH(authorizeProfileId) {
  const ids = authorizeProfileId.split("/");
  const customerProfileId = ids[0];
  const paymentProfileId = ids[1];
  return dispatch => {
    dispatch({
      type: GET_ACH_PAYMENT_PROFILE_REQUEST,
      error: null,
      isLoading: true
    })
    return axios.get(`/paymentProfileACH/${customerProfileId}/${paymentProfileId}`).then(response => {
      let error = '';
      
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }else if(response.data.error){
        error = response.data.error ? response.data.AuthNetResult?.message : response.data.error;
      }
      dispatch({
        type: GET_ACH_PAYMENT_PROFILE_RESPONSE,
        data: response.data?.profile,
        error: error
      })
    }).catch(err => {
      let error = ''
      if (err.response?.data?.message) {
        error = err.response?.data?.message;
      }
      dispatch({
        type:GET_ACH_PAYMENT_PROFILE_FAILURE,
        error: error
      })
    });
  }
}

export function updatePaymentProfile(payload, type) {
  let url = type == "ach" ? "/paymentProfileACH" : "/paymentProfileCC";
  return dispatch => {
    dispatch({
      type: UPDATE_PAYMENT_PROFILE_REQUEST,
      error: null,
      isLoading: true
    })
    return axios.post(url, payload).then(response => {
      let error = '';
      if (response.data.errors && response.data.errors.length > 0) {
        error = response.data.errors[0].message;
      }else if(response.data.error){
        error = response.data.error ? response.data.AuthNetResult?.message : response.data.error;
      }
      dispatch({
        type: UPDATE_PAYMENT_PROFILE_RESPONSE,
        error: error
      })
    }).catch(err => {
      let error = ''
      if (err.response?.data?.message) {
        error = err.response?.data?.message;
      }
      dispatch({
        type: UPDATE_PAYMENT_PROFILE_FAILURE,
        error: error
      })
    });
  }
}
