// Imports
import axios from 'axios';
import cookie from 'js-cookie';
import moment from 'moment';
import { updateMeters } from '../../meter/api/actions';

// Attempt at subclassing all of this overhead from these calls
import { GET, POST, PUT, DEL } from "../../genericAPI/api/actions"

require('../../../setup/config/apiConfig');

// Actions Types
export const ACCOUNT_OBJECT_RESET = "ACCOUNT_OBJECT_RESET";
export const UPDATE_CLIENT_ACCOUNT_OBJECT = "UPDATE_CLIENT_ACCOUNT_OBJECT";
export const UPDATE_ACCOUNT_NOTES_ARRAY = "UPDATE_ACCOUNT_NOTES_ARRAY";

export const GET_ACCOUNT_BALANCE_REQUEST = "GET_ACCOUNT_BALANCE_REQUEST";
export const GET_ACCOUNT_BALANCE_RESPONSE = "GET_ACCOUNT_BALANCE_RESPONSE";

export const UPDATE_ACCOUNT_REQUEST = "HANDLE_GET_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_RESPONSE = "HANDLE_GET_ACCOUNT_RESPONSE";

export const GET_ACCOUNT_REQUEST = "HANDLE_GET_ACCOUNT_REQUEST";
export const GET_ACCOUNT_RESPONSE = "HANDLE_GET_ACCOUNT_RESPONSE";
export const GET_ACCOUNT_FAILURE = "HANDLE_GET_ACCOUNT_FAILURE";

export const CREATE_ADDRESS_REQUEST = "CREATE_ADDRESS_REQUEST";
export const CREATE_ADDRESS_RESPONSE = "CREATE_ADDRESS_RESPONSE";
export const CREATE_ADDRESS_FAILURE = "CREATE_ADDRESS_FAILURE";

export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_RESPONSE = "UPDATE_ADDRESS_RESPONSE";
export const UPDATE_ADDRESS_FAILURE = "UPDATE_ADDRESS_FAILURE";

export const DELETE_ADDRESS_REQUEST = "DELETE_ADDRESS_REQUEST";
export const DELETE_ADDRESS_RESPONSE = "DELETE_ADDRESS_RESPONSE";
export const DELETE_ADDRESS_FAILURE = "DELETE_ADDRESS_FAILURE";

export const GET_ACCOUNT_BY_ACCTNUM_REQ = "GET_ACCOUNT_BY_ACCTNUM_REQ";
export const GET_ACCOUNT_BY_ACCTNUM_RESP = "GET_ACCOUNT_BY_ACCTNUM_RESP";
export const GET_ACCOUNT_BY_ACCTNUM_FAILURE = "GET_ACCOUNT_BY_ACCTNUM_FAILURE";

export const GET_ACCOUNT_LEDGER_REQUEST = "GET_ACCOUNT_LEDGER_REQUEST";
export const GET_ACCOUNT_LEDGER_RESPONSE = "GET_ACCOUNT_LEDGER_RESPONSE";
export const GET_ACCOUNT_LEDGER_FAILURE = "GET_ACCOUNT_LEDGER_FAILURE";
export const UPDATE_CLIENT_ACCOUNT_LEDGER = "UPDATE_CLIENT_ACCOUNT_LEDGER";

export const REFRESH_CLIENT_ACCOUNT_BALANCE = "REFRESH_CLIENT_ACCOUNT_BALANCE";

export const HYDRATE_ACCOUNT_REQUEST = "HYDRATE_ACCOUNT_REQUEST";
export const HYDRATE_ACCOUNT_RESPONSE = "HYDRATE_ACCOUNT_RESPONSE";



export const CREATE_ACCOUNT_MOVEOUT_REQUEST = 'CREATE_ACCOUNT_MOVEOUT_REQUEST';
export const CREATE_ACCOUNT_MOVEOUT_RESPONSE = 'CREATE_ACCOUNT_MOVEOUT_RESPONSE';
export const CREATE_ACCOUNT_MOVEOUT_FAILURE = 'CREATE_ACCOUNT_MOVEOUT_FAILURE';


export const CREATE_ACCOUNT_MOVEIN_REQUEST = 'CREATE_ACCOUNT_MOVEIN_REQUEST';
export const CREATE_ACCOUNT_MOVEIN_RESPONSE = 'CREATE_ACCOUNT_MOVEIN_RESPONSE';
export const CREATE_ACCOUNT_MOVEIN_FAILURE = 'CREATE_ACCOUNT_MOVEIN_FAILURE';

export const UPDATE_LOOKED_UP_METER = "UPDATE_LOOKED_UP_METER";
export const METER_HISTORY_LOOKUP_REQUEST = "METER_HISTORY_LOOKUP_REQUEST";
export const METER_HISTORY_LOOKUP_RESPONSE = "METER_HISTORY_LOOKUP_RESPONSE";
export const RESET_METER_ACCOUNT_READINGS = "RESET_METER_ACCOUNT_READINGS";

export const LINK_ACCOUNT_TO_USER_REQUEST = "LINK_ACCOUNT_TO_USER_REQUEST";
export const LINK_ACCOUNT_TO_USER_RESPONSE = "LINK_ACCOUNT_TO_USER_RESPONSE";
export const LINK_ACCOUNT_TO_USER_FAILURE = "LINK_ACCOUNT_TO_USER_FAILURE";

export function updateSelectedAccount(newAccountObject) {
  return { type: UPDATE_CLIENT_ACCOUNT_OBJECT, newAccountObject };
}
export function refreshSelectedAccountBalance(newBalanceObject) {
  return { type: REFRESH_CLIENT_ACCOUNT_BALANCE, newBalanceObject };
}
export function updateAccountNotes(notesArray) {
  return { type: UPDATE_ACCOUNT_NOTES_ARRAY, notesArray };
}
export function updateAccountLedger(newAccountLedger) {
  return { type: UPDATE_CLIENT_ACCOUNT_LEDGER, newAccountLedger };
}
export function updateLookedUpAccountMeter(newMeterHistory, meterId) {
  return { type: UPDATE_LOOKED_UP_METER, newMeterHistory, meterId };
}

export function updateAccountAddress(forwardingAddress) {
  return PUT("accountaddress", forwardingAddress.uuid, forwardingAddress, UPDATE_ADDRESS_REQUEST, UPDATE_ADDRESS_RESPONSE, UPDATE_ADDRESS_FAILURE);
}

export function createNewAccountAddress(forwardingAddress) {
  return POST("accountaddress", "", forwardingAddress, CREATE_ADDRESS_REQUEST, CREATE_ADDRESS_RESPONSE, CREATE_ADDRESS_FAILURE
  );
}

export function deleteForwardingAddress(forwardingAddress) {
  return DEL("accountaddress", forwardingAddress.uuid, forwardingAddress, DELETE_ADDRESS_REQUEST, DELETE_ADDRESS_RESPONSE, DELETE_ADDRESS_FAILURE);
}

// Actions
export function getAccountInfoByAccountUUID(accountUUID, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/account/" + accountUUID)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updateSelectedAccount(response.data));
          dispatch({
            type: GET_ACCOUNT_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_ACCOUNT_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ACCOUNT_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getAccountByAccountNumber(accountNumber) {
  return POST("account/fullpreview/batch/acct-numbers", "", { accountNums: [accountNumber] }, GET_ACCOUNT_BY_ACCTNUM_REQ, GET_ACCOUNT_BY_ACCTNUM_RESP, GET_ACCOUNT_BY_ACCTNUM_FAILURE);
}


export function lookupAccountMeterHistory(meterId, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: METER_HISTORY_LOOKUP_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/meterreading/meter/" + meterId)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updateLookedUpAccountMeter(response.data, meterId));
          dispatch({
            type: METER_HISTORY_LOOKUP_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: METER_HISTORY_LOOKUP_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: METER_HISTORY_LOOKUP_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getAccountBalance(accountId, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_BALANCE_REQUEST,
      isLoading,
    });
    return axios
      .get("/accountbalance/account/" + accountId)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(refreshSelectedAccountBalance(response.data));
          dispatch({
            type: GET_ACCOUNT_BALANCE_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_ACCOUNT_BALANCE_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ACCOUNT_BALANCE_RESPONSE,
          error: error,
        });
      });
  };
}

export function getAccountLedger(accountNumber, ledgerIsLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_LEDGER_REQUEST,
      ledgerIsLoading,
    });
    return axios
      .get("/accountledger/account/" + accountNumber)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updateAccountLedger(response.data));
          dispatch({
            type: GET_ACCOUNT_LEDGER_RESPONSE,
            ledgerError: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_ACCOUNT_LEDGER_RESPONSE,
            ledgerIsLoading: false,
            ledgerError: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ACCOUNT_LEDGER_FAILURE,
          ledgerIsLoading: false,
          ledgerError: "Error: UNKNOWN",
        });
      });
  };
}

export function hydrateAccount(accountUUID, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: HYDRATE_ACCOUNT_REQUEST,
      isLoading,
    });
    return axios
      .get("/account/hydrate/" + accountUUID)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updateSelectedAccount(response.data));
          dispatch({
            type: HYDRATE_ACCOUNT_RESPONSE,
            isLoading: false,
            error: error,
          });
          // If a unit meter or meters are found, look up all the meter reading history for those meters:
          if (
            response.data.unit &&
            response.data.unit.meters &&
            response.data.unit.meters.length > 0
          ) {
            dispatch(updateMeters(response.data.unit.meters));
            dispatch({
              type: RESET_METER_ACCOUNT_READINGS,
            });
            response.data.unit.meters.map((meter) => {
              dispatch(lookupAccountMeterHistory(meter.id));
            });
          }
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HYDRATE_ACCOUNT_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: HYDRATE_ACCOUNT_RESPONSE,
          error: error,
        });
      });
  };
}

export function updateCurrentAccountInDB(updatedAccount, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNT_REQUEST,
      isLoading,
    });
    return axios
      .put("/account/" + updatedAccount.uuid, updatedAccount)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: UPDATE_ACCOUNT_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: UPDATE_ACCOUNT_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_ACCOUNT_RESPONSE,
          error: error,
        });
      });
  };
}

export function submitAccountMoveOutRequest(moveOutRequest, isLoading = false) {
  return dispatch => {
    dispatch({
      type: CREATE_ACCOUNT_MOVEOUT_REQUEST,
      isLoading
    });
    return axios.post('/customermoveout/', moveOutRequest)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem('user'))
        if (currentUser) {
          dispatch({
            type: CREATE_ACCOUNT_MOVEOUT_RESPONSE,
            error: error
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: CREATE_ACCOUNT_MOVEOUT_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          })
        }
      })
      .catch(error => {
        dispatch({
          type: CREATE_ACCOUNT_MOVEOUT_FAILURE,
          error: error
        })
      })
  }
}
export function linkAccountToUser(accountNumber, accountVerification, verifyByOption, isLoading = false) {
  return dispatch => {
    dispatch({
      type: LINK_ACCOUNT_TO_USER_REQUEST,
      isLoading
    });
    return axios.post('/account-user-link/', { accountNumber, accountVerification, verifyByOption })
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem('user'))
        if (currentUser) {
          dispatch({
            type: LINK_ACCOUNT_TO_USER_RESPONSE,
            error: error
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: LINK_ACCOUNT_TO_USER_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          })
        }
      })
      .catch(error => {
        let err = error.response?.data?.ERROR ? error.response.data.ERROR : error
        dispatch({
          type: LINK_ACCOUNT_TO_USER_FAILURE,
          error: err
        })
      })
  }
}

export function submitAccountMoveInRequest(moveInRequest, isLoading = false) {
  return dispatch => {
    dispatch({
      type: CREATE_ACCOUNT_MOVEIN_REQUEST,
      isLoading
    });
    return axios.post('/customermovein/', moveInRequest)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem('user'))
        if (currentUser) {
          dispatch({
            type: CREATE_ACCOUNT_MOVEIN_RESPONSE,
            error: error
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: CREATE_ACCOUNT_MOVEIN_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          })
        }
      })
      .catch(error => {
        dispatch({
          type: CREATE_ACCOUNT_MOVEIN_FAILURE,
          error: error
        })
      })
  }
}

export function refreshLookedUpAccountMeters(data) {
  return (dispatch) => {
    dispatch({
      type: METER_HISTORY_LOOKUP_REQUEST,
      isLoading: true,
    });
    // If a unit meter or meters are found, look up all the meter reading history for those meters:
    if (data.unit && data.unit.meters && data.unit.meters.length > 0) {
      data.unit.meters.map((meter) => {
        dispatch(lookupAccountMeterHistory(meter.id));
      });
      dispatch({
        type: METER_HISTORY_LOOKUP_RESPONSE,
      });
    }
  };
}
