// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const GET_METERS = "GET_METERS";
export const GET_METERS_RESPONSE = "GET_METERS_RESPONSE";
export const GET_METERS_FAIL = "GET_METERS_FAIL";

export const GET_METER = "GET_METER";
export const GET_METER_RESPONSE = "GET_METER_RESPONSE";
export const GET_METER_FAIL = "GET_METER_FAIL";

export const POST_METER = "POST_METER";
export const POST_METER_RESPONSE = "POST_METER_RESPONSE";
export const POST_METER_FAIL = "POST_METER_FAIL";

export const PUT_METER = "PUT_METER";
export const PUT_METER_RESPONSE = "PUT_METER_RESPONSE";
export const PUT_METER_FAIL = "PUT_METER_FAIL";


export const CREATE_METER_READ_REQUEST = "CREATE_METER_READ_REQUEST";
export const CREATE_METER_READ_RESPONSE = "CREATE_METER_READ_RESPONSE";
export const CREATE_METER_READ_FAIL = "CREATE_METER_READ_FAIL";

export const RESET_NEW_METER_READ = "RESET_NEW_METER_READ";

export const UPDATE_METER = "UPDATE_METER";

export function updateMeters(meters){
    return { type: UPDATE_METER, meters: meters };
}

export function resetNewMeterRead(){
  return { type: RESET_NEW_METER_READ };
}

export function get(searchQuery, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_METERS,
      isLoading: isLoading,
    });
    return axios
      .get("/meter", { params: null })
      .then((response) => {
        dispatch({
          type: GET_METERS_RESPONSE,
          meters: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_METERS_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

// TODO implement search
export function getByUnitUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_METERS,
      isLoading: isLoading,
    });
    return axios
      .get(`/unit/${uuid}/meter`, {})
      .then((response) => {
        dispatch({
          type: GET_METERS_RESPONSE,
          meters: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_METERS_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_METER,
      isLoading: isLoading,
    });
    return axios
      .get(`/meter/${uuid}`, {})
      .then((response) => {
        dispatch({
          type: GET_METER_RESPONSE,
          meter: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_METER_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function postByUnitUuid(unitUuid, meter, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: POST_METER,
      isLoading,
    });
    return axios
      .post(`/unit/${unitUuid}/meter`, meter)
      .then((response) => {
        dispatch({
          meter: response.data,
          type: POST_METER_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: POST_METER_FAIL,
          error: error,
        });
      });
  };
}

export function createNewMeterReading(meterReading, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: CREATE_METER_READ_REQUEST,
      isLoading,
    });
    return axios
      .post(`/meterreading`, meterReading)
      .then((response) => {
        dispatch({
          meter: response.data,
          type: CREATE_METER_READ_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: CREATE_METER_READ_FAIL,
          error: error,
        });
      });
  };
}

export function deleteByUnitUuid(unitUuid, meterUuid, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: POST_METER,
      isLoading,
    });
    return axios
      .delete(`/unit/${unitUuid}/meter/${meterUuid}`)
      .then((response) => {
        dispatch({
          meter: response.data,
          type: POST_METER_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: POST_METER_FAIL,
          error: error,
        });
      });
  };
}

export function put(meter, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: PUT_METER,
      isLoading,
    });
    return axios
      .put("/meter/" + meter.uuid, meter)
      .then((response) => {
        dispatch({
          meter: response.data,
          type: PUT_METER_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: PUT_METER_FAIL,
          error: error,
        });
      });
  };
}
