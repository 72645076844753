import {
  GET_PROPERTY_UTILITIES,
  GET_PROPERTY_UTILITIES_RESPONSE,
  GET_PROPERTY_UTILITY,
  GET_PROPERTY_UTILITY_RESPONSE,
  POST_PROPERTY_UTILITY,
  POST_PROPERTY_UTILITY_FAIL,
  POST_PROPERTY_UTILITY_RESPONSE,
  PUT_PROPERTY_UTILITY,
  PUT_PROPERTY_UTILITY_FAIL,
  PUT_PROPERTY_UTILITY_RESPONSE,
  RESET,
  UPDATE_PROPERTY_UTILITY_LOCAL_STATE,
} from "./actions";

const initialState = {
  isLoading: false,
  propertyUtilityIsLoading: false,
  postPropertyUtilityError: null,
  putPropertyUtilityError: null,
  error: null,
  data: {
    propertyUtilitiesList: {
      items: [],
    },
    propertyUtility: null,
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case UPDATE_PROPERTY_UTILITY_LOCAL_STATE:
      let newStateData = state.data;
      newStateData.propertyUtility = action.newPropertyUtility || null;
      return {
        ...state,
        data: newStateData,
      };
    case GET_PROPERTY_UTILITY:
      return {
        ...state,
        error: null,
        propertyUtilityIsLoading: action.isLoading,
      };
    case GET_PROPERTY_UTILITY_RESPONSE:
      data.propertyUtility = action.propertyUtility;
      return {
        ...state,
        data: data,
        error: action.error,
        propertyUtilityIsLoading: false,
      };
    case GET_PROPERTY_UTILITIES:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_PROPERTY_UTILITIES_RESPONSE:
      data.propertyUtilitiesList.items = action.propertyUtilities;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case POST_PROPERTY_UTILITY:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case POST_PROPERTY_UTILITY_RESPONSE:
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case POST_PROPERTY_UTILITY_FAIL:
      return {
        ...state,
        data: data,
        error: action.error,
        postPropertyUtilityError: false,
      };
    case PUT_PROPERTY_UTILITY:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case PUT_PROPERTY_UTILITY_RESPONSE:
      return {
        ...state,
        data: { propertyUtility: action.propertyUtility },
        error: action.error,
        isLoading: false,
      };
    case PUT_PROPERTY_UTILITY_FAIL:
      return {
        ...state,
        data: data,
        error: action.error,
        putPropertyUtilityError: false,
      };
    default:
      return {
        ...state,
      };
  }
};
