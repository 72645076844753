// Imports
import axios from "axios";
import { setSnackbar } from "../../snackbar/api/snackbar";
import { getAddress, getPosition } from "../../../setup/geocode";
// Attempt at subclassing all of this overhead from these calls
import { GET, POST, PUT, DEL } from "../../genericAPI/api/actions"
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const GET_PROPERTIES = "GET_PROPERTIES";
export const GET_PROPERTIES_RESPONSE = "GET_PROPERTIES_RESPONSE";
export const GET_PROPERTIES_FAIL = "GET_PROPERTIES_FAIL";
export const POST_PROPERTY = "POST_PROPERTY";
export const POST_PROPERTY_RESPONSE = "POST_PROPERTY_RESPONSE";
export const POST_PROPERTY_FAIL = "POST_PROPERTY_FAIL";
export const PUT_PROPERTY = "PUT_PROPERTY";
export const PUT_PROPERTY_RESPONSE = "PUT_PROPERTY_RESPONSE";
export const PUT_PROPERTY_FAIL = "PUT_PROPERTY_FAIL";
export const GET_PROPERTY = "GET_PROPERTY";
export const GET_PROPERTY_RESPONSE = "GET_PROPERTY_RESPONSE";
export const GET_PROPERTY_FAIL = "GET_PROPERTY_FAIL";
export const GET_PROPERTY_RIDER_RATE_REQUEST = "GET_PROPERTY_RIDER_RATE_REQUEST";
export const GET_PROPERTY_RIDER_RATE_RESPONSE = "GET_PROPERTY_RIDER_RATE_RESPONSE";
export const GET_PROPERTY_RIDER_RATE_FAIL = "GET_PROPERTY_RIDER_RATE_FAIL";
export const UPDATE_PROPERTY_RIDER_RATE_REQUEST = "UPDATE_PROPERTY_RIDER_RATE_REQUEST";
export const UPDATE_PROPERTY_RIDER_RATE_RESPONSE = "UPDATE_PROPERTY_RIDER_RATE_RESPONSE";
export const CREATE_PROPERTY_RIDER_RATE_REQUEST = "CREATE_PROPERTY_RIDER_RATE_REQUEST";
export const CREATE_PROPERTY_RIDER_RATE_RESPONSE = "CREATE_PROPERTY_RIDER_RATE_RESPONSE";
export const DELETE_PROPERTY_RIDER_RATE_REQUEST = "DELETE_PROPERTY_RIDER_RATE_REQUEST";
export const DELETE_PROPERTY_RIDER_RATE_RESPONSE = "DELETE_PROPERTY_RIDER_RATE_RESPONSE";
export const UPDATE_LOCAL_PROPERTY_RIDER_RATES = "UPDATE_LOCAL_PROPERTY_RIDER_RATES";
export const SET_PROPERTY_COORDS = "SET_PROPERTY_COORDS";

// Summary data call to get all of the new items
export const GET_ALL_COMMON_DATA_REQUEST = "GET_ALL_COMMON_DATA_REQUEST";
export const GET_ALL_COMMON_DATA_RESPONSE = "GET_ALL_COMMON_DATA_RESPONSE";


// Common area charges crud
export const GET_PROPERTY_COMMON_CHARGE_DATA_REQUEST = "GET_PROPERTY_COMMON_CHARGE_DATA_REQUEST";
export const GET_PROPERTY_COMMON_CHARGE_DATA_RESPONSE = "GET_PROPERTY_COMMON_CHARGE_DATA_RESPONSE";

export const CREATE_PROPERTY_COMMON_CHARGE_DATA_REQUEST = "CREATE_PROPERTY_COMMON_CHARGE_DATA_REQUEST";
export const CREATE_PROPERTY_COMMON_CHARGE_DATA_RESPONSE = "CREATE_PROPERTY_COMMON_CHARGE_DATA_RESPONSE";

export const UPDATE_PROPERTY_COMMON_CHARGE_DATA_REQUEST = "UPDATE_PROPERTY_COMMON_CHARGE_DATA_REQUEST";
export const UPDATE_PROPERTY_COMMON_CHARGE_DATA_RESPONSE = "UPDATE_PROPERTY_COMMON_CHARGE_DATA_RESPONSE";

export const DELETE_PROPERTY_COMMON_CHARGE_DATA_REQUEST = "DELETE_PROPERTY_COMMON_CHARGE_DATA_REQUEST";
export const DELETE_PROPERTY_COMMON_CHARGE_DATA_RESPONSE = "DELETE_PROPERTY_COMMON_CHARGE_DATA_RESPONSE";

// roll over add items crud
export const UPDATE_RESIDUAL_AMOUNTS_STORE = "UPDATE_RESIDUAL_AMOUNTS_STORE";
export const GET_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST = "GET_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST";
export const GET_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE = "GET_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE";

export const CREATE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST = "CREATE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST";
export const CREATE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE = "CREATE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE";

export const UPDATE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST = "UPDATE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST";
export const UPDATE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE = "UPDATE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE";

export const DELETE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST = "DELETE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST";
export const DELETE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE = "DELETE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE";


// roll over add items transactions crud

export const UPDATE_COMMON_CHARGES_STORE = "UPDATE_COMMON_CHARGES_STORE";
export const UPDATE_RESIDUAL_AMOUNT_TRANSACTIONS_STORE = "UPDATE_RESIDUAL_AMOUNT_TRANSACTIONS_STORE";

export const GET_COMMON_CHARGES_TRANSACTIONS_REQUEST = "GET_COMMON_CHARGES_TRANSACTIONS_REQUEST";
export const GET_COMMON_CHARGES_TRANSACTIONS_RESPONSE = "GET_COMMON_CHARGES_TRANSACTIONS_RESPONSE";

export const CREATE_COMMON_CHARGES_TRANSACTIONS_REQUEST = "CREATE_COMMON_CHARGES_TRANSACTIONS_REQUEST";
export const CREATE_COMMON_CHARGES_TRANSACTIONS_RESPONSE = "CREATE_COMMON_CHARGES_TRANSACTIONS_RESPONSE";

export const UPDATE_COMMON_CHARGES_TRANSACTIONS_REQUEST = "UPDATE_COMMON_CHARGES_TRANSACTIONS_REQUEST";
export const UPDATE_COMMON_CHARGES_TRANSACTIONS_RESPONSE = "UPDATE_COMMON_CHARGES_TRANSACTIONS_RESPONSE";

export const DELETE_COMMON_CHARGES_TRANSACTIONS_REQUEST = "DELETE_COMMON_CHARGES_TRANSACTIONS_REQUEST";
export const DELETE_COMMON_CHARGES_TRANSACTIONS_RESPONSE = "DELETE_COMMON_CHARGES_TRANSACTIONS_RESPONSE";

export function updateLocalPropertyRiderRates(newRiderRates) {
  return { type: UPDATE_LOCAL_PROPERTY_RIDER_RATES, newRiderRates }
}

export function lookupSearchResultPropertiesLatAndLng(properties) {
  return (dispatch) => {
    properties.map((property, index) => {
      let address = `${property.name} ${property.city} ${property.state} ${property.zip}`
      getAddress(address).then(coords => {
        // coords has the lat lng and the address on it, update that list item appropriately in state
        dispatch({ type: SET_PROPERTY_COORDS, propIndex: index, newCoords: coords });
      }).catch(error => {
        // set error here 
      })
    });
  }
}

export function get(searchQuery, isLoading = true, lookupLatAndLong = false) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTIES,
      isLoading: isLoading,
    });
    let queryParams =
      searchQuery && searchQuery.length > 0
        ? {
          name: searchQuery,
          propertyManagerName: searchQuery,
          developerName: searchQuery,
        }
        : {};
    return axios
      .get("/property", { params: queryParams })
      .then((response) => {
        dispatch({
          type: GET_PROPERTIES_RESPONSE,
          properties: response.data,
        });
        if (lookupLatAndLong) {
          dispatch(lookupSearchResultPropertiesLatAndLng(response.data));
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTIES_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY,
      isLoading: isLoading,
    });
    return axios
      .get(`/property/${uuid}`)
      .then((response) => {
        dispatch({
          type: GET_PROPERTY_RESPONSE,
          property: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function post(property, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: POST_PROPERTY,
      isLoading,
    });
    return axios
      .post("/property/", property)
      .then((response) => {
        dispatch({
          data: response.data,
          type: POST_PROPERTY_RESPONSE,
        });
        //dispatch(setSnackbar(true, "success", "Property Created"));
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: POST_PROPERTY_FAIL,
          error: error,
        });
        //dispatch(setSnackbar(true, "error", "Could not create property"));
      });
  };
}

export function put(property, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: POST_PROPERTY,
      isLoading,
    });
    return axios
      .put("/property/" + property.uuid, property)
      .then((response) => {
        dispatch({
          data: response.data,
          type: PUT_PROPERTY_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: PUT_PROPERTY_FAIL,
          error: error,
        });
      });
  };
}









export function getPropRiderRatesByPropByUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_RIDER_RATE_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(`/utilityriderrate/property/${uuid}`)
      .then((response) => {
        dispatch({
          type: GET_PROPERTY_RIDER_RATE_RESPONSE,
          propertyRiderRates: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_RIDER_RATE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function deleteRiderRate(riderRateUUID, propertyUuid) {
  return dispatch => {
    dispatch({
      type: DELETE_PROPERTY_RIDER_RATE_REQUEST,
    });
    const payload = { "propertyUuid": propertyUuid, "utilityRiderRateUUID": riderRateUUID }
    return axios.post('/deleteutilityriderrate/property/', payload)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {
          dispatch({
            type: DELETE_PROPERTY_RIDER_RATE_RESPONSE,
            error: error
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: DELETE_PROPERTY_RIDER_RATE_RESPONSE,
            error: "ERROR! NO USER LOGGED IN!"
          })
        }
      })
      .catch(error => {
        dispatch({
          type: DELETE_PROPERTY_RIDER_RATE_RESPONSE,
          error: error
        })
      })
  }
}

export function createRiderRate(riderRates, propertyUuid) {
  return dispatch => {
    dispatch({
      type: CREATE_PROPERTY_RIDER_RATE_REQUEST,
    });
    const payload = { "propertyUuid": propertyUuid, "utilityRiderRates": riderRates }

    return axios.post('/createutilityriderrate/property/', payload)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {

          dispatch({
            type: CREATE_PROPERTY_RIDER_RATE_RESPONSE,
            newData: response.data,
            error: error
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: CREATE_PROPERTY_RIDER_RATE_RESPONSE,
            error: "ERROR! NO USER LOGGED IN!"
          })
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_PROPERTY_RIDER_RATE_REQUEST,
          error: error
        })
      })
  }
}


export function updateRiderRate(riderRate) {
  return dispatch => {
    dispatch({
      type: UPDATE_PROPERTY_RIDER_RATE_REQUEST,
    });
    return axios.put('/utilityriderrate/' + riderRate.uuid, riderRate)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {
          dispatch({
            type: UPDATE_PROPERTY_RIDER_RATE_RESPONSE,
            error: error
          })
          dispatch(setSnackbar(true, "success", "Successfully updated rider rate."));
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: UPDATE_PROPERTY_RIDER_RATE_RESPONSE,
            error: "ERROR! NO USER LOGGED IN!"
          })
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_PROPERTY_RIDER_RATE_RESPONSE,
          error: error
        })
        dispatch(setSnackbar(true, "error", "Failed to update rider rate."));

      })
  }
}

export function syncRiderRatesBetweenProperties(sourcePropUuid, targetPropUuid) {
  const payload = { "fromPropertyUuid": sourcePropUuid, "toPropertyUuid": targetPropUuid }
  return dispatch => {
    dispatch({
      type: UPDATE_PROPERTY_RIDER_RATE_REQUEST,
    });
    return axios.post('/utilityriderrate/property/sync', payload)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem('user'))
        if (updatedUser) {
          dispatch({
            type: UPDATE_PROPERTY_RIDER_RATE_RESPONSE,
            error: error
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: UPDATE_PROPERTY_RIDER_RATE_RESPONSE,
            error: "ERROR! NO USER LOGGED IN!"
          })
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_PROPERTY_RIDER_RATE_RESPONSE,
          error: error
        })
      })
  }
}

// Generic summary 
export function getAllCommonChargeRelatedDataByPropertyId(propertyId) {
  return dispatch => {
    dispatch(GET("commonchargedata", propertyId, GET_ALL_COMMON_DATA_REQUEST, GET_ALL_COMMON_DATA_RESPONSE));
  };
}


export function updatePropertyCommonChargesStoreObject(newCommonCharges) {
  return { type: UPDATE_COMMON_CHARGES_STORE, newCommonCharges }
}

export function updateResidualAmountsStoreObject(newResidualAmounts) {
  return { type: UPDATE_RESIDUAL_AMOUNTS_STORE, newResidualAmounts }
}

export function updateResidualAmountsTransactionsStoreObject(newResidualAmountTransactions) {
  return { type: UPDATE_RESIDUAL_AMOUNT_TRANSACTIONS_STORE, newResidualAmountTransactions }
}

export function getPropertyCommonCharges(propertyId) {
  return dispatch => {
    dispatch(GET("commonchargeexpenses", propertyId, GET_PROPERTY_COMMON_CHARGE_DATA_REQUEST, GET_PROPERTY_COMMON_CHARGE_DATA_RESPONSE));
  };
}

export function createPropertyCommonCharge(newCommonCharge) {
  return dispatch => {
    dispatch(POST("commonexpense", "", newCommonCharge, CREATE_PROPERTY_COMMON_CHARGE_DATA_REQUEST, CREATE_PROPERTY_COMMON_CHARGE_DATA_RESPONSE));
  };
}


export function updatePropertyCommonCharges(updatedCommonCharge) {
  return dispatch => {
    dispatch(PUT("commonexpense", updatedCommonCharge.id, updatedCommonCharge, UPDATE_PROPERTY_COMMON_CHARGE_DATA_REQUEST, UPDATE_PROPERTY_COMMON_CHARGE_DATA_RESPONSE));
  };
}

export function deletePropertyCommonCharges(commonChargeUuid) {
  return dispatch => {
    dispatch(DEL("commonexpense", commonChargeUuid, DELETE_PROPERTY_COMMON_CHARGE_DATA_REQUEST, DELETE_PROPERTY_COMMON_CHARGE_DATA_RESPONSE));
  };
}


export function getPropertyRolloverItems(propertyId) {
  return dispatch => {
    dispatch(GET("residualamount", propertyId, GET_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST, GET_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE));
  };
}

export function createPropertyRolloverItem(rollOverItem) {
  return dispatch => {
    dispatch(POST("residualamount", "", rollOverItem, CREATE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST, CREATE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE));
  };
}

export function updatePropertyRolloverItem(rollOverItem) {
  return dispatch => {
    dispatch(PUT("residualamount", rollOverItem.id, rollOverItem, UPDATE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST, UPDATE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE));
  };
}

export function deletePropertyRolloverItem(rollOverUuid) {
  return dispatch => {
    dispatch(DEL("residualamount", rollOverUuid, DELETE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST, DELETE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE));
  };
}


export function createPropertyRolloverItemTransaction(transaction) {
  return dispatch => {
    dispatch(POST("residualamounttransaction", "", transaction, CREATE_COMMON_CHARGES_TRANSACTIONS_REQUEST, CREATE_COMMON_CHARGES_TRANSACTIONS_RESPONSE));
  };
}

export function updatePropertyRolloverItemTransaction(transaction) {
  return dispatch => {
    dispatch(PUT("residualamounttransaction", transaction.id, transaction, UPDATE_COMMON_CHARGES_TRANSACTIONS_REQUEST, UPDATE_COMMON_CHARGES_TRANSACTIONS_RESPONSE));
  };
}

export function deletePropertyRolloverItemTransaction(transactionUuid) {
  return dispatch => {
    dispatch(DEL("residualamounttransaction", transactionUuid, DELETE_COMMON_CHARGES_TRANSACTIONS_REQUEST, DELETE_COMMON_CHARGES_TRANSACTIONS_RESPONSE));
  };
}