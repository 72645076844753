// App Imports
import { isEmpty } from '../../../setup/helpers'
import { 
  UPDATE_SELECTED_USER_PROFILE, 
  REGISTER_NEW_USER_REQUEST, 
  REGISTER_NEW_USER_RESPONSE, 
  GET_USER_FROM_ACCOUNT_RESPONSE,
  GET_USER_FROM_ACCOUNT_REQUEST
} from './actions'

// Initial State
export const userInitialState = {
  error: null,
  isLoading: false,
  isAuthenticated: false,
  details: null
}

// State
export default (state = userInitialState, action) => {
  switch (action.type) {

    case UPDATE_SELECTED_USER_PROFILE:
      if (action.newData.id){
        return {
          ...state,
          details: action.newData,
        }
      }
      else{
        return {
          ...state,
        }
      }
        
      case REGISTER_NEW_USER_REQUEST:
        return{
        ...state,
        error: null,
        isLoading: action.isLoading
      }

      case REGISTER_NEW_USER_RESPONSE:
        return{
        ...state,
        error: action.error,
        isLoading: false
      }
      case GET_USER_FROM_ACCOUNT_REQUEST:
        return{
        ...state,
        error: null,
        isLoading: action.isLoading
      }

      case GET_USER_FROM_ACCOUNT_RESPONSE:
        return{
        ...state,
        error: action.error,
        isLoading: false
      }

      default:
        return state
    }
}