// Imports
import axios from 'axios'
import cookie from 'js-cookie'



// Attempt at subclassing all of this overhead from these calls
import { GET, POST, PUT, DEL } from "../../genericAPI/api/actions"

// App Imports
require('../../../setup/config/apiConfig');

// Actions Types
export const BILL_GET_ACCOUNT_BILLS_REQUEST = 'BILL_GET_ACCOUNT_BILLS_REQUEST'
export const BILL_GET_ACCOUNT_BILLS_RESPONSE = 'BILL_GET_ACCOUNT_BILLS_RESPONSE'
export const BILL_GET_ACCOUNT_BILLS_FAILURE = 'BILL_GET_ACCOUNT_BILLS_FAILURE'
export const BILL_ACCOUNT_BILLS_RESET = 'BILL_ACCOUNT_BILLS_RESET'
export const SET_BILL_LIST = 'BILL/SET_BILL_LIST'


export const BILL_GET_OWED_ACCOUNTS_REQUEST = 'BILL_GET_OWED_ACCOUNTS_REQUEST'
export const BILL_GET_OWED_ACCOUNTS_RESPONSE = 'BILL_GET_OWED_ACCOUNTS_RESPONSE'
export const BILL_GET_OWED_ACCOUNTS_FAILURE = 'BILL_GET_OWED_ACCOUNTS_FAILURE'

export const BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST = 'BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST'
export const BILL_CREAT_NEW_ACCOUNT_BILL_RESPONSE = 'BILL_CREAT_NEW_ACCOUNT_BILL_RESPONSE'

export const BILL_GET_PM_BILLS_REQUEST = 'BILL_GET_PM_BILLS_REQUEST'
export const BILL_GET_PM_BILLS_RESPONSE = 'BILL_GET_PM_BILLS_RESPONSE'
export const SET_PM_BILL_LIST = 'BILL/SET_PM_BILL_LIST'

export const BILL_UPDATE_NEW_ACCOUNT_BILL_IN_PROGRESS = 'BILL_UPDATE_NEW_ACCOUNT_BILL_IN_PROGRESS'
export const SET_ERROR = 'SET_ERROR';

// Actions
export function updatePmBillPacketList(data, resetCache, isBaseGroup) {
  return { type: SET_PM_BILL_LIST, data, resetCache, isBaseGroup }
}
export function setBills(data) {
  return { type: SET_BILL_LIST, data }
}
export function updateNewAccountBillInProgress(newBillData) {
  return { type: BILL_UPDATE_NEW_ACCOUNT_BILL_IN_PROGRESS, newBillData }
}

export function setError(error) {
  return { type: SET_ERROR, error };
}

export function GetUserAccountsWithUnpaidBalance({ userUuid, pmUuid, propertyUuid }) {
  var target = "?";
  if (userUuid) { target = target + "userUuid=" + userUuid + "&"; }
  if (pmUuid && pmUuid != null) { target = target + "pmUuid=" + pmUuid + "&"; }
  if (propertyUuid && propertyUuid != null) { target = target + "propertyUuid=" + propertyUuid + "&"; }
  // filter out Late fees
  target = target + "noLateFee=true&";

  return GET("account/user-account-unpaid-balance", target, BILL_GET_OWED_ACCOUNTS_REQUEST, BILL_GET_OWED_ACCOUNTS_RESPONSE);
}

export function searchForPmBillPacketsByFilters({ page, rowsPerPage, pmUuid, propertyUuid, isLoading = true, isBaseGroup = false, resetCache = false }) {
  // provide default values because api endpoint breaks otherwise
  var target = "?";
  if (page || page == 0) { target = target + "page=" + page + "&"; }
  if (rowsPerPage && rowsPerPage != null || rowsPerPage == 0) { target = target + "pageLimit=" + rowsPerPage + "&"; }
  if (pmUuid && pmUuid != null) { target = target + "pmUuid=" + pmUuid + "&"; }
  if (propertyUuid && propertyUuid != null) { target = target + "propertyUuid=" + propertyUuid + "&"; }
  target = target + "withLineItems=true&";
  return (dispatch) => {
    dispatch({
      type: BILL_GET_PM_BILLS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/propertymanagerbills-filtered/" + target)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }else {
          response.data.items = response.data.items.filter(bill => bill.billType != "LATEFEE")
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updatePmBillPacketList(response.data, resetCache, isBaseGroup));
          dispatch({
            type: BILL_GET_PM_BILLS_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: BILL_GET_PM_BILLS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: BILL_GET_PM_BILLS_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}


export function searchForAccountBillsByFilters({ page, rowsPerPage, accountUuid, billType, searchQuery, propertyUuid, emailed, printed, posted, accountStatus, accountType, isLoading = true, isBaseGroup = false, resetCache = false }) {
  // provide default values because api endpoint breaks otherwise
  var target = "?";
  if (page || page == 0) { target = target + "page=" + page + "&"; }
  if (rowsPerPage && rowsPerPage != null || rowsPerPage == 0) { target = target + "pageLimit=" + rowsPerPage + "&"; }
  if (accountUuid && accountUuid != null) { target = target + "accountUuid=" + accountUuid + "&"; }
  let endDate = new Date();
  const additionOfDays = 1;
  endDate.setDate(endDate.getDate() + additionOfDays);
  endDate = endDate.toISOString()
  endDate = endDate.substring(0, 10);

  let startDate = new Date();
  const additionOfYears = 1;
  startDate.setFullYear(startDate.getFullYear() - additionOfYears); // For subtract use minus (-)
  startDate = startDate.toISOString();
  startDate = startDate.substring(0, 10);

  target += `startDate=${startDate}&endDate=${endDate}`
  

  return (dispatch) => {
    dispatch({
      type: BILL_GET_ACCOUNT_BILLS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/accountbills-filtered/" + target)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }else{ 
          response.data.items = response.data.items.filter(bill =>bill.type != "LATEFEE")
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch({
            type: BILL_GET_ACCOUNT_BILLS_RESPONSE,
            isLoading: false,
            error: error,
            data: response.data
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: BILL_GET_ACCOUNT_BILLS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: BILL_GET_ACCOUNT_BILLS_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

// Gets user bills based on account number only. Replaced with /accountbills-filtered endpoint
export function getUserBills(target, isLoading = true) {
  return dispatch => {
    dispatch({
      type: BILL_GET_ACCOUNT_BILLS_REQUEST,
      isLoading: isLoading
    });
    return axios.get('/accountbill/accountnum/' + target)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem('user'))
        if (currentUser) {
          //Updating the bills obj in state
          dispatch({
            type: BILL_GET_ACCOUNT_BILLS_RESPONSE,
            isLoading: false,
            error: error,
            data: response.data
          });
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: BILL_GET_ACCOUNT_BILLS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          })
        }
      })
      .catch(error => {
        dispatch({
          type: BILL_GET_ACCOUNT_BILLS_FAILURE,
          isLoading: false,
          error: error
        })
      })
  }
}

export function createNewAccountBill(newAccountBill, isFullBill, accountNum, isLoading = true) {
  return dispatch => {
    dispatch({
      type: BILL_CREAT_NEW_ACCOUNT_BILL_REQUEST,
      isLoading
    });

    return axios.post('/generate-new-billableitems', { payload: newAccountBill, isFullBill, accountNum })
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem('user'))
        if (currentUser) {
          dispatch({
            type: BILL_CREAT_NEW_ACCOUNT_BILL_RESPONSE,
            error: error
          })
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: BILL_CREAT_NEW_ACCOUNT_BILL_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          })
        }
      })
      .catch(error => {
        dispatch(setError(error));
        throw new Error(error);
      })
  }
}