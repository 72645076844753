import {
  RESET,
  SEARCH_REQUEST,
  SEARCH_RESPONSE,
  GET_UNIT,
  GET_UNIT_RESPONSE,
  POST_UNIT,
  POST_UNIT_RESPONSE,
  POST_UNIT_FAIL,
  PUT_UNIT,
  PUT_UNIT_RESPONSE,
  PUT_UNIT_FAIL,
} from "./actions";

const initialState = {
  isLoading: false,
  unitIsLoading: false,
  postUnitError: null,
  putUnitError: null,
  error: null,
  data: {
    unitsList: {
      items: [],
    },
    unit: {},
    searchQuery: null,
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case SEARCH_RESPONSE:
      var data = state.data;
      data.unitsList = {
        items: action.units,
      };
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case GET_UNIT:
      return {
        ...state,
        error: null,
        unitIsLoading: action.isLoading,
      };
    case GET_UNIT_RESPONSE:
      data.unit = action.unit;
      return {
        ...state,
        data: data,
        error: action.error,
        unitIsLoading: false,
      };
    case POST_UNIT:
      return {
        ...state,
        error: null,
        unitIsLoading: action.isLoading,
      };
    case POST_UNIT_RESPONSE:
      return {
        ...state,
        data: data,
        error: action.error,
        unitIsLoading: false,
      };
    case POST_UNIT_FAIL:
      return {
        ...state,
        data: data,
        postUnitError: action.error,
        unitIsLoading: false,
      };
    case PUT_UNIT:
      return {
        ...state,
        error: null,
        unitIsLoading: action.isLoading,
      };
    case PUT_UNIT_RESPONSE:
      return {
        ...state,
        data: data,
        error: action.error,
        unitIsLoading: false,
      };
    case PUT_UNIT_FAIL:
      return {
        ...state,
        data: data,
        putUnitError: action.error,
        unitIsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
