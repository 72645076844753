import {
  RESET,
  GET_METER,
  GET_METER_RESPONSE,
  GET_METER_FAIL,
  GET_METERS,
  GET_METERS_RESPONSE,
  GET_METERS_FAIL,
  POST_METER,
  POST_METER_RESPONSE,
  POST_METER_FAIL,
  PUT_METER,
  PUT_METER_RESPONSE,
  PUT_METER_FAIL,
  CREATE_METER_READ_REQUEST,
  CREATE_METER_READ_RESPONSE,
  CREATE_METER_READ_FAIL,
  UPDATE_METER,
  RESET_NEW_METER_READ
} from "./actions";
import moment from 'moment'
let blankMeterRead = {
  meterId: -1,
  readAmount: 0,
  meterReadTypeId: "",
  firstRead: 0,
  finalRead: 0,
};
const today = moment().format("MM/DD/YYYY");
const initialState = {
  isLoading: false,
  meterIsLoading: false,
  error: null,
  postMeterError: null,
  putMeterError: null,
  data: {
    metersList: {
      items: [],
    },
    meter: {},
    searchQuery: null,
  },
  newMeterRead:blankMeterRead
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case UPDATE_METER:
      data.metersList.items = action.meters;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case RESET_NEW_METER_READ:
      let revertedMeterRead = {
        meterId: -1,
        readAmount: 0,
        meterReadTypeId: "",
        firstRead: 0,
        finalRead: 0,
      };
      return {
        ...state,
        newMeterRead: revertedMeterRead,
      };
    case GET_METER:
      return {
        ...state,
        error: null,
        meterIsLoading: action.isLoading,
      };
    case GET_METER_RESPONSE:
      data.meter = action.meter;
      return {
        ...state,
        data: data,
        error: action.error,
        meterIsLoading: false,
      };
    case GET_METER_FAIL:
      data.metersList.items = null;
      return {
        ...state,
        data: data,
        error: action.error,
        meterIsLoading: false,
      };
    case CREATE_METER_READ_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case CREATE_METER_READ_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case CREATE_METER_READ_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case GET_METERS:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_METERS_RESPONSE:
      data.metersList.items = action.meters;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case GET_METERS_FAIL:
      data.metersList.items = null;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case POST_METER:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case POST_METER_RESPONSE:
      return {
        ...state,
        data: data,
        postMeterError: action.error,
        isLoading: false,
      };
    case POST_METER_FAIL:
      return {
        ...state,
        data: data,
        postMeterError: action.error,
        isLoading: false,
      };
    case PUT_METER:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case PUT_METER_RESPONSE:
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case PUT_METER_FAIL:
      return {
        ...state,
        data: data,
        putMeterError: action.error,
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
};
