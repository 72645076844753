// Imports
import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Component
const RoutePrivate = (props) => {
  //console.log("private route current user cached obj");
  //console.log(props.user);
  const user = JSON.parse(window.localStorage.getItem("user"));
  if (!user) {
    return <Redirect to="/login" />;
  }
  for (const pageParam in props.computedMatch.params) {
    //console.log(`${pageParam}: ${props.computedMatch.params[pageParam]}`);
    // if the uuid isnt my users and its not in my cached user access array, return to login
    if (user.access) {
      // with the client side auth checker, params that shouldnt be inclueded in the search should be added to the ennd of this if statement
      if (
        pageParam == "accountUuid" ||
        pageParam == "propUuid" ||
        pageParam == "userUuid" ||
        pageParam == "pmUuid"
      ) {
        if (
          user.uuid != props.computedMatch.params[pageParam] &&
          !user.access.includes(props.computedMatch.params[pageParam]) &&
          pageParam != "type" &&
          !(pageParam == "accountUuid" && user.isPM) &&
          pageParam != "propUuid"
        ) {
          console.log(
            "ERROR! ATTEMPTED ACCESS ON NON AUTHORIZED ITEM: " +
              props.computedMatch.params[pageParam]
          );
          return <Redirect to="/login" />;
        }
      }
    }
    // if the access hasnt been generated yet all we can go by is the uuid passed for our user object which has to be our own or its not authorized
    else {
      if (user.uuid != props.computedMatch.params[pageParam]) {
        console.log(
          "ERROR! ATTEMPTED ACCESS ON USER UUID THAT DOES NOT MATCH CACHED USER UUID: " +
            props.computedMatch.params[pageParam]
        );
        return <Redirect to="/login" />;
      }
    }
  }

  return props.user.isAuthenticated ? (
    props.role ? (
      user.role === props.role ? (
        <Route {...props} component={props.component} />
      ) : (
        <Redirect to="/login" />
      )
    ) : (
      <Route {...props} component={props.component} />
    )
  ) : (
    <Redirect to="/login" />
  );
};

// Component Properties
RoutePrivate.propTypes = {
  user: PropTypes.object.isRequired,
};

// Component State
function routePrivateState(state) {
  return {
    user: state.user,
  };
}

export default connect(routePrivateState, {})(withRouter(RoutePrivate));
