import Geocode from "react-geocode";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDmVWMr5vZTfXCQ8NNWSBPHg2mSW2ws3EY");

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.

Geocode.enableDebug();

export function getAddress(address) {
  // return Geocode.fromAddress(address)
  //   .then(
  //     (response) => {
  //       const { lat, lng } = response.results[0].geometry.location;
  //       const address = response.results[0].formatted_address;
  //       //console.log(lat, lng);
  //       return { lat, lng, address };
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   )
  //   .catch((error) => {
  //     console.error(error);
  //   });
}

// If browser supports navigator.geolocation, generate Lat/Long else let user know there is an error
export function getPosition(onSuccess, OnFailure) {
  if (navigator.geolocation) {
    return navigator.geolocation.getCurrentPosition(onSuccess, OnFailure);
  } else {
    //need to put this error in the UI
    console.log("Sorry, Geolocation is not supported by this browser."); // console.log is browser does not support geolocation
  }
}
