// App Imports
import { isEmpty } from '../../../setup/helpers'
import {
  PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_REQUEST,
  PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_RESPONSE,
  PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_FAILURE,
  PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_RESET,
  PAYMENT_METHOD_UPDATE_REQUEST,
  PAYMENT_METHOD_UPDATE_RESPONSE,
  PAYMENT_METHOD_UPDATE_FAILURE,
  CREATE_AUTO_PAYMENTS_REQUEST,
  CREATE_AUTO_PAYMENTS_RESPONSE,
  CREATE_AUTO_PAYMENTS_FAILURE,
  UPDATE_AUTO_PAYMENTS_REQUEST,
  UPDATE_AUTO_PAYMENTS_RESPONSE,
  UPDATE_AUTO_PAYMENTS_FAILURE,
  GET_ACH_PAYMENT_PROFILE_REQUEST,
  GET_ACH_PAYMENT_PROFILE_RESPONSE,
  GET_ACH_PAYMENT_PROFILE_FAILURE,
  GET_CC_PAYMENT_PROFILE_REQUEST,
  GET_CC_PAYMENT_PROFILE_RESPONSE,
  GET_CC_PAYMENT_PROFILE_FAILURE, 
  SET_PAYMENT_METHOD_LIST,
  RESET_BANK_PAYMENT_OBJECT,
  RESET_CARD_PAYMENT_OBJECT
  
} from './actions'

// Initial State
const userPaymentMethodInitialState = {
  isLoading: false,
  error: null,
  data: {
    cc: [],
    ach: []
  },
  bankPaymentMethod: {
        saved: 1,
        saveName: '',
        nameOnAccount: '',
        bankName: '',
        bankRoutingNum: '',
        bankAcctTypeId: '',
        routingNumberConfirm: '',
        bankAcctNum: '',
        accountNumberConfirmed: '',
        billingAddress1: '',
        billingAddress2: '',
        billingState: '',
        billingZip: ''
  },
  currentAccountUuid: '',
  currentPMAccountUuid: '',

  newAutoPay: null,

  cardPaymentMethod: {
    saved: 1,
    saveName: '',
    nameOnCard: '',
    ccNum: '',
    ccTypeId: 3,
    ccExpMonth: '',
    ccExpYear: '',
    cardCvv: '',
    billingAddress1: '',
    billingAddress2: '',
    billingState: '',
    billingZip: ''
  },
  formIsComplete: false
}

// State
export default (state = userPaymentMethodInitialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHOD_LIST:
      return {
        ...state,
        data: action.data,
      }

    case PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null
      }

    case PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: action.data
      }

    case PAYMENT_METHOD_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null
      }

    case PAYMENT_METHOD_UPDATE_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: action.data
      }
    case PAYMENT_METHOD_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
            
    case CREATE_AUTO_PAYMENTS_RESPONSE:
      return {
        ...state,
        isLoading: action.isLoading,
        newAutoPay: action.newAutoPay,
        error: action.error
      }

    case PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case UPDATE_AUTO_PAYMENTS_FAILURE:
      return{
        ...state,
        isLoading: action.isLoading,
        error: action.error
      }
    case GET_ACH_PAYMENT_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,

      }
    case GET_ACH_PAYMENT_PROFILE_RESPONSE:
      let updateBankObject = state.bankPaymentMethod;
      let addr = action.data.billTo.address || "" ;
      var address1 = "";
      var address2 = "";
      if (addr.includes(",")) {
        addr = addr.split(",")
        address1 = addr[0]
        address2 = addr[1]
        address2 = address2.trim()
      }else{
        address1 = addr;
      }

      updateBankObject.billingAddress1 = address1;
      updateBankObject.billingAddress2 = address2;
      updateBankObject.billingCity = action.data.billTo.city;
      updateBankObject.billingState = action.data.billTo.state;
      updateBankObject.billingZip = action.data.billTo.zip;

      updateBankObject.bankAcctNum = action.data.payment.bankAccount.accountNumber;
      updateBankObject.accountNumberConfirmed = action.data.payment.bankAccount.accountNumber;
      updateBankObject.bankRoutingNum = action.data.payment.bankAccount.routingNumber;
      updateBankObject.routingNumberConfirm = action.data.payment.bankAccount.routingNumber;
      updateBankObject.nameOnAccount = action.data.payment.bankAccount.nameOnAccount;
      updateBankObject.customerPaymentProfileId = action.data.customerPaymentProfileId;
      updateBankObject.customerProfileId = action.data.customerProfileId;

      return {
        ...state,
        isLoading: action.isLoading,
        bankPaymentMethod: updateBankObject
      }
    case GET_ACH_PAYMENT_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case GET_CC_PAYMENT_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case GET_CC_PAYMENT_PROFILE_RESPONSE:
      let updateCardObject = state.cardPaymentMethod;

      let addrCC = action.data?.billTo?.address || "" ;
       address1 = "";
       address2 = "";
      if (addrCC.includes(",")) {
        addrCC = addrCC.split(",")
        address1 = addrCC[0]
        address2 = addrCC[1]
        address2 = address2.trim()
      }else{
        address1 = addrCC;
      }

      updateCardObject.billingAddress1 = address1;
      updateCardObject.billingAddress2 = address2;
      updateCardObject.billingCity = action.data?.billTo?.city;
      updateCardObject.billingState = action.data?.billTo?.state;
      updateCardObject.billingZip = action.data?.billTo?.zip;

      updateCardObject.ccNum = action.data?.payment?.creditCard?.cardNumber;
      let expDates = action.data?.payment?.creditCard?.expirationDate;
      expDates = expDates ? splitExp(expDates) : "";
      updateCardObject.ccExpYear = Number(expDates[0]);
      updateCardObject.ccExpMonth = Number(expDates[1]);
      updateCardObject.ccTypeId = action.data?.payment?.creditCard?.cardType == "MasterCard" ? 3 : 4;  

      updateCardObject.customerPaymentProfileId = action.data.customerPaymentProfileId;
      updateCardObject.customerProfileId = action.data.customerProfileId;

      return{
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        cardPaymentMethod: updateCardObject
      }
    case GET_CC_PAYMENT_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case RESET_BANK_PAYMENT_OBJECT:
        return {
          ...state,
          bankPaymentMethod: {
            saved: 1,
            saveName: '',
            nameOnAccount: '',
            bankName: '',
            bankRoutingNum: '',
            bankAcctTypeId: '',
            routingNumberConfirm: '',
            bankAcctNum: '',
            accountNumberConfirmed: '',
            billingAddress1: '',
            billingAddress2: '',
            billingState: '',
            billingZip: ''
        }
      } 
      case RESET_CARD_PAYMENT_OBJECT:
        return {
          ...state,
          cardPaymentMethod: {
            saved: 1,
            saveName: '',
            nameOnCard: '',
            ccNum: '',
            ccTypeId: 3,
            ccExpMonth: '',
            ccExpYear: '',
            cardCvv: '',
            billingAddress1: '',
            billingAddress2: '',
            billingState: '',
            billingZip: ''
          }
        }
    case PAYMENT_METHOD_GET_ACCOUNT_PAYMENT_METHODS_RESET:
      return Object.assign({}, userPaymentMethodInitialState)

    default:
      return state
  }

  function splitExp(expDates) {
    return expDates.split("-")
  }
}