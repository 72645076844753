// Imports
import axios from "axios";

// Attempt at subclassing all of this overhead from these calls
import { GET, POST, PUT, DEL } from "../../genericAPI/api/actions"

// App Imports
require("../../../setup/config/apiConfig");

// Actions Types
export const SET_PAYMENT_LIST = "PAYMENT/SET_PAYMENT_LIST";
export const ADD_PAYMENT_TO_PAYMENT_LIST =
  "PAYMENT/ADD_PAYMENT_TO_PAYMENT_LIST";
export const PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST =
  "PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST";
export const PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE =
  "PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE";

export const PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST =
  "PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST";
export const PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE =
  "PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE";
export const PAYMENT_MAKE_ACCOUNT_PAYMENT_FAILURE =
"PAYMENT_MAKE_ACCOUNT_PAYMENT_FAILURE";

export const PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_REQUEST =
  "PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_REQUEST";
export const PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_RESPONSE =
  "PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_RESPONSE";
export const PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_FAILURE =
  "PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_FAILURE";

export const PAYMENT_GET_ALL_PM_PAYMENTS_REQUEST = "PAYMENT_GET_ALL_PM_PAYMENTS_REQUEST";
export const PAYMENT_GET_ALL_PM_PAYMENTS_RESPONSE = "PAYMENT_GET_ALL_PM_PAYMENTS_RESPONSE";
export const PAYMENT_GET_ALL_PM_PAYMENTS_FAILURE = "PAYMENT_GET_ALL_PM_PAYMENTS_FAILURE";



export const GET_BULK_PM_PAYMENTS_REQUEST = "GET_BULK_PM_PAYMENTS_REQUEST";
export const GET_BULK_PM_PAYMENTS_RESPONSE = "GET_BULK_PM_PAYMENTS_RESPONSE";

export const PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE =
  "PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE";
export const PAYMENT_ACCOUNT_PAYMENTS_RESET =
  "PAYMENT_GET_ACCOUNT_PAYMENTS_RESET";

export const PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS =
  "PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS";

export const PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS_ERRORS =
  "PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS_ERRORS";

export const PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_REQUEST =
  "PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_REQUEST";
export const PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE =
  "PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE";
export const ADD_ACCOUNTS_TO_LIST = "ADD_ACCOUNTS_TO_LIST";
export const UPDATE_BULK_CHECK = "UPDATE_BULK_CHECK";
export const UPDATE_BULK_CHECK_ACCOUNTS = "UPDATE_BULK_CHECK_ACCOUNTS";
export const RESET_BULK_CHECK_ENTRY = "RESET_BULK_CHECK_ENTRY";

export const GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_REQ =
  "GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_REQ";
export const GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP =
  "GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP";

export const ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_REQUEST =
  "ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_REQUEST";
export const ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE =
  "ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE";
// Actions

export function setPayments(data) {
  return { type: SET_PAYMENT_LIST, data };
}
export function updateNewAccountPaymentInProgress(newPaymentData) {
  return {
    type: PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS,
    newPaymentData,
  };
}
export function updateNewAccountPaymentErrors(newErrors) {
  return {
    type: PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS_ERRORS,
    newErrors,
  };
}

export function addNewPaymentToList(payment) {
  return { type: ADD_PAYMENT_TO_PAYMENT_LIST, payment };
}

export function updateBulkCheck(newBulkCheck) {
  return { type: UPDATE_BULK_CHECK, newBulkCheck };
}

export function addAccountsSelectedToCheck(accounts) {
  return { type: ADD_ACCOUNTS_TO_LIST, accounts };
}
export function resetBulkCheckEntry() {
  return { type: RESET_BULK_CHECK_ENTRY };
}

export function updateLocalBulkCheckAccountsItemsState(accounts) {
  return { type: UPDATE_BULK_CHECK_ACCOUNTS, accounts };
}

export function getAllAccountPayments(
  page,
  pageLimit,
  accountUuid,
  isLoading = true,
  isReset = false
) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_REQUEST,
      isLoading: isLoading,
      isReset: isReset,
    });
    let queryParams = {
      accountUuid,
      page: page,
      pageLimit: pageLimit,
    };
    return axios
      .get("/payments/account", { params: queryParams })
      .then((response) => {
        dispatch({
          type: PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_RESPONSE,
          isLoading: false,
          data: response.data,
          isReset: isReset,
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}


export function getBulkCheckDailyPaymentsSummary(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_REQ,
      isLoading: isLoading,
    });
    return axios
      .get("/payment/bulkCheckSummary")
      .then((response) => {
        dispatch({
          type: GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP,
          isLoading: false,
          bulkCheckCurrentSummary: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function searchForPmPaymentsByFilters(
  page,
  pageLimit,
  pmUuid,
  isLoading = true,
  isReset = false) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_GET_ALL_PM_PAYMENTS_REQUEST,
      isLoading: isLoading,
      isReset: isReset,
    });
    let queryParams = {
      pmUuid,
      page: page,
      pageLimit: pageLimit,
    };
    return axios
      .get("/payments/pm", { params: queryParams })
      .then((response) => {
        dispatch({
          type: PAYMENT_GET_ALL_PM_PAYMENTS_RESPONSE,
          isLoading: false,
          data: response.data,
          isReset: isReset,
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_GET_ALL_PM_PAYMENTS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  }
};

export function getPmBulkPayments({ page, rowsPerPage, pmUuid, propertyUuid, isLoading = true, isBaseGroup = false, resetCache = false }) {
  var target = "?";
  if (page || page == 0) { target = target + "page=" + page + "&"; }
  if (rowsPerPage && rowsPerPage != null || rowsPerPage == 0) { target = target + "pageLimit=" + rowsPerPage + "&"; }
  if (pmUuid && pmUuid != null) { target = target + "pmUuid=" + pmUuid + "&"; }
  if (propertyUuid && propertyUuid != null) { target = target + "propertyUuid=" + propertyUuid + "&"; }
  return (dispatch) => {
    dispatch({
      type: PAYMENT_GET_ALL_PM_PAYMENTS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/getPmBulkPayments/" + target)
      .then((response) => {
        dispatch({
          type: PAYMENT_GET_ALL_PM_PAYMENTS_RESPONSE,
          isLoading: true,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_GET_ALL_PM_PAYMENTS_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  }
};

export function addSelectedPmBillAccountsSelectedToCheck(
  pmBills,
  isLoading = true
) {
  return (dispatch) => {
    dispatch({
      type: ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .post("/account/fullpreview/accountByPmPacket", pmBills)
      .then((response) => {
        dispatch({
          type: ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE,
          isLoading: false,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

// Get all account payments made
export function getUserPayments(target, isLoading = true) {

  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 1);
  endDate = endDate.toISOString();
  endDate = endDate.substring(0, 10);

  let startDate = new Date();
  const additionOfYears = 1;
  startDate.setFullYear(startDate.getFullYear() - additionOfYears); // For subtract use minus (-)
  startDate = startDate.toISOString();
  startDate = startDate.substring(0, 10);

  target += `&startDate=${startDate}&endDate=${endDate}`

  return (dispatch) => {
    dispatch({
      type: PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/payments/account?accountUuid=" + target)
      .then((response) => {
        let error = "";
        if (response.data.error) {
          error = response.data;
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem("user"));
        const filteredItems = response.data.items.filter(item => item.paymentObjectTypeDesc != 'Unearned Inc. Dist.' )
        dispatch(
          setPayments({ items: filteredItems, toUpdate: [], toDelete: [] })
        );
        if (updatedUser) {
          //Updating the bills obj in state
          dispatch({
            type: PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE,
            isLoading: false,
            error: error,
            data: response.data,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

// Make a new account payment
// NOTE: Expects {accountUuid, typeName, paymentSubmissionPayload (achUUID or ccUUID or new 1 time ach or new one time cc transaction)}
export function createNewPayment(paymentSubmissionPayload) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST,
      isLoading: true,
    });
    return axios
      .post("/payment/handlePaymentSubmission", paymentSubmissionPayload)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }else if(response.data.error){
          error = response.data.error ? response.data.AuthNetResult?.message : response.data.error;
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem("user"));
        if (updatedUser) {
          // For bulk customer payments we just need the validation of the transaction
          if (response.data.items || response.data.transactionResult) {
              dispatch({
                type: PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
                isLoading: false,
                error: error || null,
                transactionId: response.data.AuthNetResult.transactionId || ""
              });
            localStorage.setItem('transactionId', JSON.stringify(response.data.AuthNetResult.transactionId))
          }
          else {
            dispatch(addNewPaymentToList(response.data));
            dispatch({
              type: PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
              isLoading: false,
              error: error,
            });
          }
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch(err => {
        let error = null;
        if(err.response.data?.AuthNetResult){
          error = err.response.data.AuthNetResult.message;
        }
        else if(err.response.data?.transactionResult) {
          error = err.response.data.transactionResult.result
        }
        else{
          error = 'error processing payment';
        }
        dispatch({
          type: PAYMENT_MAKE_ACCOUNT_PAYMENT_FAILURE,
          error: error
        })
      });
  };
}

export function pushUpdateToCurrentlySelectedAccountPaymentsList(payment) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_REQUEST,
    });
    return axios
      .put("/payment/" + payment.uuid, payment)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const updatedUser = JSON.parse(window.localStorage.getItem("user"));
        if (updatedUser) {
          dispatch({
            type: PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE,
          error: error,
        });
      });
  };
}
