// Imports
import axios from 'axios'
import cookie from 'js-cookie'


// App Imports
require('../../../setup/config/apiConfig');

// Actions Types
export const DISCONNECT_GET_ACCOUNT_DISCONNECTS_REQUEST = 'DISCONNECT_GET_ACCOUNT_DISCONNECTS_REQUEST'
export const DISCONNECT_GET_ACCOUNT_DISCONNECTS_RESPONSE = 'DISCONNECT_GET_ACCOUNT_DISCONNECTS_RESPONSE'
export const DISCONNECT_GET_ACCOUNT_DISCONNECTS_FAILURE = 'DISCONNECT_GET_ACCOUNT_DISCONNECTS_FAILURE'
export const DISCONNECT_ACCOUNT_DISCONNECTS_RESET = 'DISCONNECT_ACCOUNT_DISCONNECTS_RESET'
export const SET_DISCONNECT_LIST = 'DISCONNECT/SET_DISCONNECT_LIST'

export const DISCONNECT_CREAT_NEW_ACCOUNT_DISCONNECT_REQUEST = 'DISCONNECT_CREAT_NEW_ACCOUNT_DISCONNECT_REQUEST'
export const DISCONNECT_CREAT_NEW_ACCOUNT_DISCONNECT_RESPONSE = 'DISCONNECT_CREAT_NEW_ACCOUNT_DISCONNECT_RESPONSE'


export const DISCONNECT_UPDATE_NEW_ACCOUNT_DISCONNECT_IN_PROGRESS = 'DISCONNECT_UPDATE_NEW_ACCOUNT_DISCONNECT_IN_PROGRESS'

// Actions
export function setDisconnects(data) {
  return { type: SET_DISCONNECT_LIST, data }
}
export function updateNewAccountDisconnectInProgress(newDisconnectData) {
  return { type: DISCONNECT_UPDATE_NEW_ACCOUNT_DISCONNECT_IN_PROGRESS, newDisconnectData }
}

// Login a user using credentials
export function getAccountDisconnectsByAccountID(target, isLoading = true) {
  return dispatch => {
    dispatch({
      type: DISCONNECT_GET_ACCOUNT_DISCONNECTS_REQUEST,
      isLoading: isLoading
    });
    return axios.get('/accountbill/accountnum/' + target)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        } 
        
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem('user'))
        if (currentUser) {
          //Updating the Disconnects obj in state
          dispatch({
            type: DISCONNECT_GET_ACCOUNT_DISCONNECTS_RESPONSE,
            isLoading: false,
            error: error,
            data: response.data
          });
        }
        else{
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: DISCONNECT_GET_ACCOUNT_DISCONNECTS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          })
        }
      })
      .catch(error => {
        dispatch({
          type: DISCONNECT_GET_ACCOUNT_DISCONNECTS_FAILURE,
          isLoading: false,
          error: error
        })
      })
  }
}

export function createNewAccountDisconnect(newAccountDisconnect, isLoading = true) {
  return dispatch => {
    dispatch({
      type: DISCONNECT_CREAT_NEW_ACCOUNT_DISCONNECT_REQUEST,
      isLoading
    });
    return axios.post('/generate-new-account-bill', newAccountDisconnect)
      .then(response => {
      let error = '';
      if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
      }
      // Update/Check if user object is the obj state
      const currentUser = JSON.parse(window.localStorage.getItem('user'))
      if (currentUser) {
        dispatch({
          type: DISCONNECT_CREAT_NEW_ACCOUNT_DISCONNECT_RESPONSE,
          error: error
        })
      }
      else{
        // if no user is found then clear object because its an unauthorized access
        dispatch({
          type: DISCONNECT_CREAT_NEW_ACCOUNT_DISCONNECT_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!",
        })
      }
      })
      .catch(error => {
        //console.log(error);
        dispatch({
          type: DISCONNECT_CREAT_NEW_ACCOUNT_DISCONNECT_RESPONSE,
          error: error
        })
      })
  }
}