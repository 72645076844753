// App Imports
import moment from "moment";
import {
  ADD_ACCOUNTS_TO_LIST,
  ADD_PAYMENT_TO_PAYMENT_LIST,
  ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_REQUEST,
  ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE,
  GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_REQ,
  GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP,
  PAYMENT_ACCOUNT_PAYMENTS_RESET,
  PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE,
  PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST,
  PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE,
  PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_FAILURE,
  PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_REQUEST,
  PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_RESPONSE,
  PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST,
  PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE,
  PAYMENT_MAKE_ACCOUNT_PAYMENT_FAILURE,
  PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_REQUEST,
  PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE,
  PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS_ERRORS,
  RESET_BULK_CHECK_ENTRY,
  SET_PAYMENT_LIST,
  UPDATE_BULK_CHECK,
  UPDATE_BULK_CHECK_ACCOUNTS,
  PAYMENT_GET_ALL_PM_PAYMENTS_REQUEST,
  PAYMENT_GET_ALL_PM_PAYMENTS_RESPONSE,
  PAYMENT_GET_ALL_PM_PAYMENTS_FAILURE,
  GET_BULK_PM_PAYMENTS_REQUEST,
  GET_BULK_PM_PAYMENTS_RESPONSE,

} from "./actions";

const today = moment().format("MM/DD/YYYY");
// Initial State
const paymentsInitialState = {
  isLoading: true,
  error: null,
  accountPaymentsTotalRows: null,
  data: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  pmPaymentsAreLoading: false,
  pmPayments: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  bulkCheckCurrentSummary: null,
  bulkCheckCurrentSummaryIsLoading: true,
  bulkCheckSelectedAccounts: {
    items: [],
    toDelete: [],
    toUpdate: [],
  },
  newBulkCheckIsLoading: false,
  newBulkCheck: {
    checkNumber: "",
    amountPaid: 0,
  },
  accountPaymentsSummary: null,
  newPayment: {},
  newPaymentSubmissionErrors: [],
  paymentUpdateSubmissionErrors: [],
  transactionId: ""
};

// State
export default (state = paymentsInitialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_LIST:
      return {
        ...state,
        data: action.data,
      };
    case UPDATE_BULK_CHECK_ACCOUNTS:
      return {
        ...state,
        bulkCheckSelectedAccounts: action.accounts,
      };
    case ADD_ACCOUNTS_TO_LIST:
      state.bulkCheckSelectedAccounts.items.map((account) => {
        for (var x = 0; x < action.accounts.length; x++) {
          if (action.accounts[x].id == account.id) {
            action.accounts.splice(x, 1);
          }
        }
      });
      let newbulkCheckSelectedAccounts = {
        toDelete: [],
        toUpdate: [],
        items: state.bulkCheckSelectedAccounts.items.concat(action.accounts),
      };
      return {
        ...state,
        bulkCheckSelectedAccounts: newbulkCheckSelectedAccounts,
      };
    case ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case ADD_SELECTED_PM_PACKET_ACCOUNTS_TO_CHECK_RESPONSE:
      if (action.data) {
        state.bulkCheckSelectedAccounts.items.map((account) => {
          for (var x = 0; x < action.data.length; x++) {
            if (action.data[x].id == account.id) {
              action.data.splice(x, 1);
            }
          }
        });
        action.data.map((account) => {
          if (!account.amountCovered && account.balance > 0) {
            account.amountCovered = account.balance;
          } else {
            account.amountCovered = 0;
          }
        });
        let newSelectedAccounts = {
          toDelete: [],
          toUpdate: [],
          items: state.bulkCheckSelectedAccounts.items.concat(action.data),
        };
        return {
          ...state,
          bulkCheckSelectedAccounts: newSelectedAccounts,
        };
      } else {
        return {
          ...state,
        };
      }

    case RESET_BULK_CHECK_ENTRY:
      return {
        ...state,
        bulkCheckSelectedAccounts: {
          toDelete: [],
          toUpdate: [],
          items: [],
        },
        newBulkCheck: {
          checkNumber: "",
          amountPaid: 0,
        },
      };
    case UPDATE_BULK_CHECK:
      return {
        ...state,
        newBulkCheck: action.newBulkCheck,
      };
    case PAYMENT_UPDATE_NEW_ACCOUNT_PAYMENT_IN_PROGRESS_ERRORS:
      return {
        ...state,
        newPaymentSubmissionErrors: action.newErrors,
      };
    case ADD_PAYMENT_TO_PAYMENT_LIST:
      if (action.payment) {
        state.data.unshift(action.payment);
      }
      return {
        ...state,
        data: state.data,
      };

    case PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_RESPONSE:
      if (action.isReset) {
        state.data.items = action.data.items;
      } else {
        var newArray = state.data.items.concat(action.data.items); //jQuery.extend(true, state.data.accountPayments.items, action.data.items);
        state.data.items = newArray;
      }
      return {
        ...state,
        data: state.data,
        error: action.error,
        isLoading: false,
        accountPaymentsSummary: action.data.summary,
        accountPaymentsTotalRows: action.data.summary.totalRows,
      };

    case PAYMENT_GET_ALL_ACCOUNT_PAYMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case PAYMENT_GET_ACCOUNT_PAYMENTS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };

    case PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };

    case GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_REQ:
      return {
        ...state,
        bulkCheckCurrentSummaryIsLoading: action.isLoading || false,
        error: null,
      };

    case GET_BULK_CHECK_PAYMENT_DAILY_SUMMARY_RESP:
      return {
        ...state,
        bulkCheckCurrentSummary: action.bulkCheckCurrentSummary || null,
        bulkCheckCurrentSummaryIsLoading: false,
        error: action.error || null,
      };
    case PAYMENT_GET_ALL_PM_PAYMENTS_REQUEST:
      return {
        ...state,
        pmPaymentsAreLoading: true,
        error: null,
      };

    case PAYMENT_GET_ALL_PM_PAYMENTS_RESPONSE:
      if (action.data) {
        var newPMData = {
          items: [],
          toDelete: [],
          toUpdate: [],
        };
        if (action.data.length > 0) {
          newPMData.items = action.data;
        }
        return {
          ...state,
          pmPayments: newPMData,
          pmPaymentsAreLoading: false,
          error: action.error || null,
        };
      }
      else {
        return {
          ...state
        };
      }
    case PAYMENT_MAKE_ACCOUNT_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };

    case PAYMENT_MAKE_ACCOUNT_PAYMENT_RESPONSE:
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        transactionId: action.transactionId
      };
    
      case PAYMENT_MAKE_ACCOUNT_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      };
    

    case PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_REQUEST:
      return {
        ...state,
      };

    case PAYMENT_UPDATE_ACCOUNT_EXISTING_PAYMENT_RESPONSE:
      state.paymentUpdateSubmissionErrors.push(action.error);
      return {
        ...state,
        paymentUpdateSubmissionErrors: state.paymentUpdateSubmissionErrors,
      };

    case PAYMENT_GET_ACCOUNT_PAYMENTS_RESPONSE:
      //Sorts initial ledger by transaction date
      const sortedPayments = action.data
        .slice()
        .sort((a, b) => new Date(b.paymentDate) - new Date(a.paymentDate));
      action.data = sortedPayments;

      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: action.data,
      };

    case PAYMENT_GET_ACCOUNT_PAYMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case PAYMENT_ACCOUNT_PAYMENTS_RESET:
      return Object.assign({}, paymentsInitialState);

    default:
      return state;
  }
};
