// Imports
import axios from 'axios'
import cookie from 'js-cookie'

require('../../../setup/config/apiConfig');
// Actions Types
export const GET_ALL_TRANSACTIONS_REQUEST = 'USER/GET_ALL_DATA_REQUEST'
export const GET_ALL_TRANSACTIONS_RESPONSE = 'USER/GET_ALL_DATA_RESPONSE'
export const UPDATE_SELECTED_USER_PROFILE = 'UPDATE_SELECTED_USER_PROFILE'
export const SET_USER = 'AUTH/SET_USER'
export const REGISTER_NEW_USER_REQUEST = 'AUTH/REGISTER_NEW_USER_REQUEST'
export const REGISTER_NEW_USER_RESPONSE = 'AUTH/REGISTER_NEW_USER_RESPONSE'
export const UPDATE_USER_REQUEST  = 'USER/UPDATE_USER_REQUEST'
export const UPDATE_USER_RESPONSE  = 'USER/UPDATE_USER_RESPONSE'
export const UPDATE_PASSWORD_REQUEST  = 'USER/UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_RESPONSE  = 'USER/UPDATE_PASSWORD_RESPONSE'

export const GET_USER_FROM_ACCOUNT_REQUEST = 'GET_USER_FROM_ACCOUNT_REQUEST'
export const GET_USER_FROM_ACCOUNT_RESPONSE = 'GET_USER_FROM_ACCOUNT_RESPONSE'



// Set updated user with new incoming payments in localStorage and state
export function setAccount(newData) {
  return {
      type: UPDATE_SELECTED_USER_PROFILE,
      newData
    };
}


// Set user token and info in localStorage and cookie
export function loginSetUserLocalStorageAndCookie(token, user) {
  // Update token
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('user', JSON.stringify(user))

  // Set cookie for SSR
  cookie.set('auth', { token, user }, { path: '/' }, { expires: 1 })
}

// Unset user token and info in localStorage and cookie
export function logoutUnsetUserLocalStorageAndCookie() {
  // Remove token
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('user');
  // Remove cookie
  cookie.remove('auth');
}

///user-by-account/:accountUuid

export function getUserByAccount(accountUUID, isLoading = true) {
  return dispatch => {
    dispatch({
      type: GET_USER_FROM_ACCOUNT_REQUEST,
      isLoading
    });
    return axios.get('/user-by-account/' + accountUUID)
      .then(response => {
      let error = '';
      if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
      } 
          
      // Update/Check if user object is the obj state
      const currentUser = JSON.parse(window.localStorage.getItem('user'))
      if (currentUser) {
        dispatch(setAccount(response.data));
        dispatch({
          type: GET_USER_FROM_ACCOUNT_RESPONSE,
          error: error
        })
      }
      else{
        // if no user is found then clear object because its an unauthorized access
        dispatch({
          type: GET_USER_FROM_ACCOUNT_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!",
        })
      }
      })
      .catch(error => {
        //console.log(error);
        dispatch({
          type: GET_USER_FROM_ACCOUNT_RESPONSE,
          error: error
        })
      })
  }
}