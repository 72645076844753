// App Imports
import { isEmpty } from '../../../setup/helpers'
import {
  SET_USER,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  RESET_REQUEST,
  RESET_RESPONSE,
  RESET__PASSWORD_REQUEST,
  RESET__PASSWORD_RESPONSE,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_RESPONSE,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESPONSE,
  UPDATE_USER_FAILURE,
  REFRESH_REQUEST,
  REFRESH_RESPONSE,
  LOGOUT,
  UPDATE_USER,
  REGISTER_NEW_USER_REQUEST,
  REGISTER_NEW_USER_RESPONSE,
  updateUserLocalStorage
} from './actions'

// Initial State
export const userInitialState = {
  error: null,
  isLoading: false,
  isAuthenticated: false,
  details: null,
}

// State
export default (state = userInitialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        details: action.user,
        error: action.error
      }

    case UPDATE_USER:
      const newUser = Object.assign({}, state.details, action.newData);
      updateUserLocalStorage(newUser);
      return {
        ...state,
        isAuthenticated: !isEmpty(state.details),
        details: newUser,
      }

    case LOGIN_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading
      }

    case LOGIN_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      }

    case UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading
      }

    case UPDATE_USER_PROFILE_RESPONSE:
      // check local storage for refreshed user obj:
      var userDetails = JSON.parse(window.localStorage.getItem('user'));
      return {
        ...state,
        error: action.error,
        details: userDetails,
        isLoading: false,
      }
    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      }

    case RESET_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading
      }

    case RESET_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      }

    case REFRESH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading
      }

    case REFRESH_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      }

    case LOGOUT:
      return {
        ...state,
        error: null,
        isLoading: false,
        isAuthenticated: false,
        details: null
      }

    case REGISTER_NEW_USER_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading
      }

    case REGISTER_NEW_USER_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      }
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      }

    default:
      return state
  }
}