import {
  RESET,
  GET_PROPERTIES,
  GET_PROPERTIES_RESPONSE,
  GET_PROPERTIES_FAIL,
  GET_PROPERTY,
  GET_PROPERTY_RESPONSE,
  GET_PROPERTY_FAIL,
  POST_PROPERTY,
  POST_PROPERTY_FAIL,
  POST_PROPERTY_RESPONSE,
  PUT_PROPERTY,
  PUT_PROPERTY_FAIL,
  PUT_PROPERTY_RESPONSE,
  GET_PROPERTY_RIDER_RATE_REQUEST,
  GET_PROPERTY_RIDER_RATE_RESPONSE,
  GET_PROPERTY_RIDER_RATE_FAIL,
  UPDATE_LOCAL_PROPERTY_RIDER_RATES,
  UPDATE_PROPERTY_RIDER_RATE_REQUEST,
  UPDATE_PROPERTY_RIDER_RATE_RESPONSE,
  CREATE_PROPERTY_RIDER_RATE_REQUEST,
  CREATE_PROPERTY_RIDER_RATE_RESPONSE,
  DELETE_PROPERTY_RIDER_RATE_REQUEST,
  DELETE_PROPERTY_RIDER_RATE_RESPONSE,
  GET_ALL_COMMON_DATA_REQUEST,
  GET_ALL_COMMON_DATA_RESPONSE,
  UPDATE_COMMON_CHARGES_STORE,
  UPDATE_RESIDUAL_AMOUNTS_STORE,
  GET_PROPERTY_COMMON_CHARGE_DATA_REQUEST,
  GET_PROPERTY_COMMON_CHARGE_DATA_RESPONSE,
  GET_COMMON_CHARGES_TRANSACTIONS_REQUEST,
  GET_COMMON_CHARGES_TRANSACTIONS_RESPONSE,
  GET_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST,
  GET_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE,
  CREATE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST,
  CREATE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE,
  UPDATE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST,
  UPDATE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE,
  DELETE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST,
  DELETE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE,
  UPDATE_RESIDUAL_AMOUNT_TRANSACTIONS_STORE,
  SET_PROPERTY_COORDS
} from "./actions";


const initialState = {
  isLoading: false,
  propertyUtilityIsLoading: false,
  postPropertyError: null,
  putPropertyError: null,
  data: {
    // For the current accounts cached locally
    propertiesList: {
      items: [],
    },
    property: {
      isLoading: false
    },
    // The Current Account Management Filters(Default is by name with no calue):
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
  propertyResidualAmountsToAdd: {
    items: [],
    toDelete: [],
    toUpdate: []
  },
  propertyResidualAmountsToAddIsLoading: true,
  propertyAdditionalCommonAreaExpenses: {
    items: [],
    toDelete: [],
    toUpdate: []
  },
  propertyRolloverItemCurrentTransactions: {
    items: [],
    toDelete: [],
    toUpdate: []
  },
  propertyAdditionalCommonAreaExpensesIsLoading: true,
  riderRatesAreLoading: true,
  riderRatesProcessingErrors: null,
  currentRiderRateTestKwhCharge: 1,
  propertyRiderRates: { items: [], toUpdate: [], toDelete: [] },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case SET_PROPERTY_COORDS:
      if (action.propIndex && action.newCoords) {
        data.propertiesList.items[action.propIndex].coords = action.newCoords;
      }
      return {
        ...state,
        data: data,
      };
    case GET_PROPERTIES:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_PROPERTIES_RESPONSE:
      data.propertiesList = {
        items: action.properties,
      };
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case GET_PROPERTY:
      return {
        ...state,
        error: null,
        propertyIsLoading: action.isLoading,
      };
    case UPDATE_COMMON_CHARGES_STORE:
      if (action.newCommonCharges) {
        return {
          ...state,
          AdditionalCommonAreaExpenses: action.newCommonCharges,
        }
      }
      return {
        ...state,
      };
    case UPDATE_RESIDUAL_AMOUNT_TRANSACTIONS_STORE:
      if (action.newResidualAmountTransactions) {
        return {
          ...state,
          propertyRolloverItemCurrentTransactions: action.newResidualAmountTransactions,
        }
      }
      return {
        ...state,
      };

    case UPDATE_RESIDUAL_AMOUNTS_STORE:
      if (action.newResidualAmounts) {
        return {
          ...state,
          propertyResidualAmountsToAdd: action.newResidualAmounts,
        }
      }
      return {
        ...state,
      };
    case GET_ALL_COMMON_DATA_REQUEST:
      return {
        ...state,
        error: null,
        propertyResidualAmountsToAddIsLoading: action.isLoading,
        propertyAdditionalCommonAreaExpensesIsLoading: action.isLoading,
      };

    case GET_ALL_COMMON_DATA_RESPONSE:
      if (!action.response.AdditionalCommonAreaExpenses || !action.response.ResidualAmountsToAdd) {
        return {
          ...state,
          error: "Error Loading Property Common Area Items",
          propertyResidualAmountsToAddIsLoading: false,
          propertyAdditionalCommonAreaExpensesIsLoading: false,
        };
      }
      return {
        ...state,
        error: null,
        propertyResidualAmountsToAddIsLoading: false,
        propertyAdditionalCommonAreaExpensesIsLoading: false,
        propertyAdditionalCommonAreaExpenses: { items: action.response.AdditionalCommonAreaExpenses, toUpdate: [], toDelete: [] },
        propertyResidualAmountsToAdd: { items: action.response.ResidualAmountsToAdd, toUpdate: [], toDelete: [] },
      };

    case GET_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST:
      return {
        ...state,
        error: null,
        propertyAdditionalCommonAreaExpensesIsLoading: action.isLoading,
      };

    case GET_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE:
      if (!action.response) {
        return {
          ...state,
          error: "Error Loading Property Charge Residual Items",
          propertyResidualAmountsToAddIsLoading: false,
        };
      }
      return {
        ...state,
        error: null,
        propertyResidualAmountsToAddIsLoading: false,
        propertyResidualAmountsToAdd: { items: action.response, toUpdate: [], toDelete: [] },
      };

    case GET_PROPERTY_COMMON_CHARGE_DATA_REQUEST:
      return {
        ...state,
        error: null,
        propertyAdditionalCommonAreaExpensesIsLoading: action.isLoading,
      };

    case GET_PROPERTY_COMMON_CHARGE_DATA_RESPONSE:
      if (!action.response) {
        return {
          ...state,
          error: "Error Loading Property Charge Residual Items",
          propertyAdditionalCommonAreaExpensesIsLoading: false,
        };
      }
      return {
        ...state,
        error: null,
        propertyAdditionalCommonAreaExpensesIsLoading: false,
        propertyAdditionalCommonAreaExpenses: { items: action.response, toUpdate: [], toDelete: [] },
      };

    case CREATE_PROPERTY_RESIDUAL_COMMON_CHARGES_REQUEST:
      return {
        ...state,
        propertyResidualAmountsToAddIsLoading: false,
      };
    case CREATE_PROPERTY_RESIDUAL_COMMON_CHARGES_RESPONSE:
      if (action.response) {
        return {
          ...state,
          error: action.error,
        };
      } return {
        ...state
      };

    case GET_PROPERTY_RIDER_RATE_REQUEST:
      return {
        ...state,
        riderRatesProcessingErrors: null,
        riderRatesAreLoading: true,
      };
    case GET_PROPERTY_RIDER_RATE_RESPONSE:
      data.propertyRiderRates = { items: action.propertyRiderRates, toUpdate: [], toDelete: [] }
      return {
        ...state,
        data: data,
        propertyRiderRates: { items: action.propertyRiderRates, toUpdate: [], toDelete: [] },
        riderRatesProcessingErrors: action.error,
        riderRatesAreLoading: false,
      };

    case UPDATE_PROPERTY_RIDER_RATE_REQUEST:
      return {
        ...state,
        riderRatesProcessingErrors: null,
      };
    case UPDATE_PROPERTY_RIDER_RATE_RESPONSE:
      return {
        ...state,
        riderRatesProcessingErrors: action.error,
      };
    case CREATE_PROPERTY_RIDER_RATE_REQUEST:
      return {
        ...state,
        riderRatesAreLoading: true,
        riderRatesProcessingErrors: null,
      };
    case CREATE_PROPERTY_RIDER_RATE_RESPONSE:
      let newRiderRates = state.propertyRiderRates.items;
      if (action.newData) {
        newRiderRates = newRiderRates.concat(action.newData)
      }
      return {
        ...state,
        propertyRiderRates: { items: newRiderRates, toUpdate: [], toDelete: [] },
        riderRatesAreLoading: false,
        riderRatesProcessingErrors: action.error,
      };
    case DELETE_PROPERTY_RIDER_RATE_REQUEST:
      return {
        ...state,
        riderRatesProcessingErrors: null,
      };
    case DELETE_PROPERTY_RIDER_RATE_RESPONSE:
      return {
        ...state,
        riderRatesProcessingErrors: action.error,
      };
    case GET_PROPERTY_RIDER_RATE_FAIL:
      return {
        ...state,
        riderRatesProcessingErrors: action.error,
        riderRatesAreLoading: false,
      };
    case UPDATE_LOCAL_PROPERTY_RIDER_RATES:
      return {
        ...state,
        propertyRiderRates: action.newRiderRates
      };



    case GET_PROPERTY_RESPONSE:
      data.property = action.property;
      return {
        ...state,
        data: data,
        error: action.error,
        propertyIsLoading: false,
      };
    case POST_PROPERTY:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case POST_PROPERTY_RESPONSE:
      const propertyItems = data.propertiesList.items.concat(action.data);
      data.propertiesList.items = propertyItems;
      return {
        ...state,
        data: data,
        error: action.error,
        propertyUtilityIsLoading: false,
      };
    case POST_PROPERTY_FAIL:
      return {
        ...state,
        data: data,
        postPropertyError: true,
      };
    case PUT_PROPERTY:
      return {
        ...state,
        error: null,
        propertyUtilityIsLoading: action.isLoading,
      };
    case PUT_PROPERTY_RESPONSE:
      //TODO data.propertyUtility = action.propertyUtility;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };
    case PUT_PROPERTY_FAIL:
      return {
        ...state,
        data: data,
        error: action.error,
        putPropertyError: false,
      };
    default:
      return {
        ...state,
      };
  }
};
