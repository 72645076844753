// Imports
import dotenv from 'dotenv'

// Load .env
dotenv.config()

// URL
export const APP_URL_API = process.env.REACT_APP_API_URL

// Port
export const PORT = process.env.PORT || 3000
