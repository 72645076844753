
import {
  FETCH_METER_READING_UPLOADS,
  SET_LOADING,
  HANDLE_ERROR,
  RECEIVED_READINGS_SUBMISSION
} from './actions'

// Initial State
const MeterReadingsUploadInitialState = {
  isLoading: false,
  error: null,
  data: {
    previousMeterReadingUploads: [],
    previewUploadedMeterReadings: [],
  }
}

// State
export default (state = MeterReadingsUploadInitialState, action) => {

  switch (action.type) {

    case FETCH_METER_READING_UPLOADS:
      const uploadData = Object.assign({}, state.data, { previousMeterReadingUploads: action.previousMeterReadingUploads });
      return { ...state, data: uploadData, isLoading: false };

    case RECEIVED_READINGS_SUBMISSION:
      const readingsData = Object.assign({}, state.data, { previewUploadedMeterReadings: action.previewUploadedMeterReadings });
      return { ...state, data: readingsData, isLoading: false };

    case SET_LOADING:
      return Object.assign({}, state, { isLoading: action.isLoading });

    case HANDLE_ERROR:
      return Object.assign({}, state, { error: action.error, isLoading: false });

    default:
      return state;
  }
}