import React from "react";

const PageError = React.lazy(() => import("./pages/PageError")); //
const StopPaymentInfo = React.lazy(() => import("./pages/PageStopPaymentInfo")); //
const PageLoginThree = React.lazy(() => import("./pages/PageLoginThree")); //
const PageUserRegister = React.lazy(() => import("./pages/PageUserRegister")); //
const Components = React.lazy(() => import("./pages/Components")); //
const PageProfileEdit = React.lazy(() => import("./pages/PageProfileEdit")); //
const PagePmAccountEdit = React.lazy(() => import("./pages/PagePmAccountEdit")); //
const PageUserEdit = React.lazy(() => import("./pages/PageUserEdit")); //
const PageInvoice = React.lazy(() => import("./pages/PageInvoice")); //
const PagePrivacy = React.lazy(() => import("./pages/PagePrivacy")); //
const PageTerms = React.lazy(() => import("./pages/PageTerms")); //
const PageContactDetail = React.lazy(() => import("./pages/PageContactDetail")); //
const PaymentConfirmation = React.lazy(() => import("./pages/PaymentConfirmation"));
const EmailPasswordReset = React.lazy(() =>
  import("./pages/EmailPasswordReset")
); //
const PasswordResetRequest = React.lazy(() =>
  import("./pages/PageRecoveryPassword")
); //
const PasswordReset = React.lazy(() => import("./pages/PageResetPassword")); //
const HelpCenterOverview = React.lazy(() =>
  import("./pages/HelpCenterOverview")
); //
const HelpCenterGuides = React.lazy(() => import("./pages/HelpCenterGuides")); //
const HelpCenterSupportRequest = React.lazy(() =>
  import("./pages/HelpCenterSupportRequest")
); //
const MoveInForm = React.lazy(() => import("./pages/MoveInForm")); //
const ShopCart = React.lazy(() => import("./pages/ShopCart")); //
const ShopCheckouts = React.lazy(() => import("./pages/ShopCheckouts")); //
const ShopMyAccount = React.lazy(() => import("./pages/ShopMyAccount")); //
const MainMenu = React.lazy(() => import("./pages/MainMenu")); //
const AccountManagement = React.lazy(() => import("./pages/AccountManagement")); //
const PropertyManagerManagement = React.lazy(() =>
  import("./pages/PmAccountManagement")
); //
const MoveOutForm = React.lazy(() => import("./pages/MoveOutForm"));
const PmNewMoveInForm = React.lazy(() => import("./pages/PmNewMoveInForm"));

const routes = [
  //Pages in use:
  { path: "/move-in", component: MoveInForm },
  {
    path: "/email-password-reset",
    component: EmailPasswordReset,
    isWithoutLayout: true,
    auth: true,
  },
  { path: "/login", component: PageLoginThree, isWithoutLayout: true },
  {
    path: "/register/:token",
    component: PageUserRegister,
    isWithoutLayout: true,
  },
  {
    path: "/password-reset-request",
    component: PasswordResetRequest,
    isWithoutLayout: true,
    auth: false,
  },
  {
    path: "/reset-password/:token/:email",
    component: PasswordReset,
    isWithoutLayout: true,
    auth: false,
  },
  { path: "/shop-cart", component: ShopCart, auth: true },
  { path: "/checkout/:pmUuid/:propUuid", component: ShopCheckouts, auth: true },
  { path: "/checkout/:pmUuid/:propUuid/:isPmAccounts", component: ShopCheckouts, auth: true },
  { path: "/checkout/:accountUuid", component: ShopCheckouts, auth: true },
  { path: "/checkout", component: ShopCheckouts, auth: true },
  { path: "/account", component: ShopMyAccount, auth: true },
  { path: "/", component: PageLoginThree, isWithoutLayout: true, exact: true },
  {
    path: "/account-management/:userUuid/:accountUuid",
    component: AccountManagement,
    auth: true,
  },
  {
    path: "/transfer-account/:userUuid/:pmUuid/:accountUuid/:propUuid",
    component: MoveOutForm,
    auth: true,
  },
  {
    path: "/transfer-account-move-in/:userUuid/:pmUuid/:propUuid",
    component: PmNewMoveInForm,
    auth: true,
  },
  {
    path: "/pm-account-management/:userUuid/:pmUuid/:propUuid",
    component: PropertyManagerManagement,
    auth: true,
  },

  //edit profile or user pages
  {
    path: "/edit-profile/:accountUuid",
    component: PageProfileEdit,
    auth: true,
  },
  {
    path: "/edit-profile/:accountUuid/:firstTimeLogin",
    component: PageProfileEdit,
    auth: true,
  },
  {
    path: "/edit-profile/pm/:pmUuid/:propUuid/:firstTimeLogin",
    component: PagePmAccountEdit,
    auth: true,
  },
  {
    path: "/edit-profile/pm/:pmUuid/:propUuid",
    component: PagePmAccountEdit,
    auth: true,
  },

  { path: "/page-terms", component: PageTerms },
  { path: "/page-privacy", component: PagePrivacy },
  { path: "/error", component: PageError, isWithoutLayout: true, exact: false },
  { path: "/page-payment-info", component: StopPaymentInfo, isWithoutLayout: true ,auth: true },

  // Main user overview menu
  { path: "/main-menu/:userUuid", component: MainMenu, auth: true },

  // Limited topbar links with no selected account
  {
    path: "/overview/edit-user-profile/:userUuid",
    component: PageUserEdit,
    auth: true,
  },
  {
    path: "/overview/edit-user-profile/:userUuid/:firstTimeLogin",
    component: PageUserEdit,
    auth: true,
  },
  { path: "/overview/helpcenter-overview", component: HelpCenterOverview },
  { path: "/overview/helpcenter-guides", component: HelpCenterGuides },
  {
    path: "/overview/helpcenter-support-request",
    component: HelpCenterSupportRequest,
  },
  { path: "/overview/contact", component: PageContactDetail, auth: true },

  // topbar links with a selected account or pm account view
  {
    path: "/helpcenter-overview/:pmUuid/:propUuid",
    component: HelpCenterOverview,
  },
  { path: "/helpcenter-guides/:pmUuid/:propUuid", component: HelpCenterGuides },
  {
    path: "/helpcenter-support-request/:pmUuid/:propUuid",
    component: HelpCenterSupportRequest,
  },

  { path: "/helpcenter-overview/:accountUuid", component: HelpCenterOverview },
  { path: "/helpcenter-guides/:accountUuid", component: HelpCenterGuides },
  {
    path: "/helpcenter-support-request/:accountUuid",
    component: HelpCenterSupportRequest,
  },

  {
    path: "/contact/:pmUuid/:propUuid",
    component: PageContactDetail,
    auth: true,
  },
  { path: "/contact/:accountUuid", component: PageContactDetail, auth: true },

  { path: "/page-invoice", component: PageInvoice },
  { path: "/payment-confirmation/:accountUuid", component: PaymentConfirmation, auth: true },
  { path: "/payment-confirmation/:pmUuid/:propUuid", component: PaymentConfirmation, auth: true },


 

  { path: "/page-invoice", component: PageInvoice },
];

export default routes;
