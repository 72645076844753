import axios from 'axios';

export const FETCH_METER_READING_UPLOADS = 'FETCH_METER_READING_UPLOADS';
export const SET_LOADING = 'SET_LOADING';
export const HANDLE_ERROR = 'HANDLE_ERROR';
export const RECEIVED_READINGS_SUBMISSION = 'RECEIVED_READINGS_SUBMISSION';

export function fetchedMeterReadingUploads(previousMeterReadingUploads) {
    return { type: FETCH_METER_READING_UPLOADS, previousMeterReadingUploads}
}

export function receivedReadingsSubmission(previewUploadedMeterReadings) {
    return { type: RECEIVED_READINGS_SUBMISSION, previewUploadedMeterReadings }
}

export function handleError(error) {
  return { type: HANDLE_ERROR, error };
}


// Actions
export function handleMeterReadingsUploadSubmission(fileData, firstRow, fileName) {

    return dispatch => {


      dispatch({
        type: SET_LOADING,
        isLoading: true
      });


      return axios.post('/handle-meter-readings-upload', { data: fileData, fileName, firstRow })
        .then(response => {

            if (response.statusCode == '400')
              throw new Error('Failed to process request.')

            if (response.statusCode == '401')
              throw new Error('Unauthorized.')

            if (response.statusCode == '500')
              throw new Error('Server error. Please try again later.')

            if (response.statusCode == '404')
              throw new Error('Route does not exist.')

            dispatch(receivedReadingsSubmission(response.data));      
        })
        .catch(error => {
          dispatch({
            type: HANDLE_ERROR,
            error: error
          })

          throw new Error(error);
        })
    }
  }



export function fetchPreviousMeterReadingUploads() {

  return dispatch => {

    return axios.get('/meter-reading-upload')
      .then(response => {
          dispatch(fetchedMeterReadingUploads(response.data));      
      })
      .catch(error => {
        
        dispatch({
          type: HANDLE_ERROR,
          error: error
        })

        throw new Error(error);
      })
  }
}