import axios from 'axios';

export const FETCHED_MOVES = 'FETCHED_MOVES';
export const SET_LOADING = 'SET_LOADING';
export const HANDLE_ERROR = 'HANDLE_ERROR';

export const DELETE_MOVE_IN = 'DELETE_MOVE_IN'
export const DELETE_MOVE_OUT = 'DELETE_MOVE_OUT'

export const UPDATED_MOVE_INS = 'UPDATED_MOVE_INS'
export const UPDATED_MOVE_OUTS = 'UPDATED_MOVE_OUTS'

export const EDITED_MOVE_IN = 'EDITED_MOVE_IN';
export const EDITED_MOVE_OUT = 'EDITED_MOVE_OUT';

export const PROCESSED_MOVE_INS = 'PROCESSED_MOVE_INS';
export const PROCESSED_MOVE_OUTS = 'PROCESSED_MOVE_OUTS';

export const CREATED_MOVE_IN = 'CREATED_MOVE_IN';

export const SET_TAB_STATE = 'SET_TAB_STATE';


export function setTabState(tabName) {
  return { type: SET_TAB_STATE, tabName}
}

export function fetchedMoves(moves) {
  return { type: FETCHED_MOVES, moves }
}

export function handleError(error) {
  return { type: HANDLE_ERROR, error };
}

export function deleteMoveIn(uuid) {
  return { type: DELETE_MOVE_IN, uuid };
}

export function deleteMoveOut(uuid) {
  return { type: DELETE_MOVE_OUT, uuid };
}

export function updatedMoveIns(uuids, keyValPair) {
  return { type: UPDATED_MOVE_INS, uuids, keyValPair };
}

export function updatedMoveOuts(uuids, keyValPair) {
  return { type: UPDATED_MOVE_OUTS, uuids, keyValPair };
}

export function editedMoveIn(originalObj, editObj) {
  return { type: EDITED_MOVE_IN, originalObj, editObj };
}

export function editedMoveOut(originalObj, editObj) {
  return { type: EDITED_MOVE_OUT, originalObj, editObj };
}


export function processedMoveIns(uuids) {
  return { type: PROCESSED_MOVE_INS, uuids };
}

export function processedMoveOuts(uuids) {
  return { type: PROCESSED_MOVE_OUTS, uuids };
}

export function createdNewMoveIn(moveIn) {
  return { type: CREATED_MOVE_IN, moveIn};
}

// Actions
export function fetchMoves(monthsOut) {

  if (!monthsOut || monthsOut == null || monthsOut == undefined)
    monthsOut = 3;

  return dispatch => {


    dispatch({
      type: SET_LOADING,
      isLoading: true
    });


    return axios.get(`/customer-moves-unprocessed?monthsOut=${monthsOut}`)
      .then(response => {
        dispatch(fetchedMoves(response.data));
      })
      .catch(error => {

        dispatch({
          type: HANDLE_ERROR,
          error
        })

        throw new Error(error);

      })
  }
}



export function deleteMove(uuid, type) {


  return dispatch => {

    dispatch({
      type: SET_LOADING,
      isLoading: true
    });

    const url = (type == 'movein') ? `/customermovein/${uuid}` : `/customermoveout/${uuid}`;

    return axios.delete(url)
      .then(response => {
        let action = (type == 'movein') ? deleteMoveIn : deleteMoveOut;
        dispatch(action(uuid));
      })
      .catch(error => {

        dispatch({
          type: HANDLE_ERROR,
          error
        })

        throw new Error(error);

      })

  }
}



export function updateBatchMoves(uuids, type, keyValPair) {

  return dispatch => {

    dispatch({
      type: SET_LOADING,
      isLoading: true
    });

    const url = (type == 'movein') ? '/update-batch-customermoveins' : '/update-batch-customermoveouts';
    const keyName = (type == 'movein') ? 'moveIns' : 'moveOuts';

    const fieldToUpdate = Object.keys(keyValPair)[0];
    const value = keyValPair[fieldToUpdate];

    const moveObjs = uuids.map(uuid => {
      return {
        uuid,
        [fieldToUpdate]: value
      }
    })


    return axios.put(url, { [keyName]: moveObjs })
      .then(response => {

        let action = (type == 'movein') ? updatedMoveIns : updatedMoveOuts;
        
        if (fieldToUpdate == 'valid') {
          uuids.forEach(uuid => {
            let deleteMove = (type == 'movein') ? deleteMoveIn : deleteMoveOut;
            dispatch(deleteMove(uuid));
          })
        }
        else
          dispatch(action(uuids, keyValPair));

      })
      .catch(error => {

        dispatch({
          type: HANDLE_ERROR,
          error
        })

        throw new Error(error);

      })

  }
}



export function editMove(originalObj, editObj, type) {


  return dispatch => {

    dispatch({
      type: SET_LOADING,
      isLoading: true
    });

    const url = (type == 'movein') ? `/edit-customermovein` : `/edit-customermoveout`;

    return axios.put(url, { originalObj, editObj })
      .then(response => {

        if (editObj.firstName && editObj.lastName) {
          editObj.accountHolder = `${editObj.firstName} ${editObj.lastName}`
        }

        if (editObj.firstName && !editObj.lastName) {
          editObj.accountHolder = `${editObj.firstName} ${originalObj.lastName}`
        }

        if (!editObj.firstName && editObj.lastName) {
          editObj.accountHolder = `${originalObj.firstName} ${editObj.lastName}`
        }

        if (editObj.meterRead == 0)
          editObj.readAmtType = null;

        let action = (type == 'movein') ? editedMoveIn : editedMoveOut;
        dispatch(action(originalObj, editObj));
      })
      .catch(error => {

        dispatch({
          type: HANDLE_ERROR,
          error
        })

        throw new Error(error);

      })

  }
}

export function processMoves(movesToProcess, type) {


  return dispatch => {

    dispatch({
      type: SET_LOADING,
      isLoading: true
    });


    const url = (type == 'movein') ? '/admin/accountcreation' : '/customermoveout/batch/process';
    const keyName = (type == 'movein') ? 'moveIns' : 'moveOuts';

    return axios.post(url, { [keyName]: movesToProcess })
      .then(response => {
        let action = (type == 'movein') ? processedMoveIns : processedMoveOuts;
        dispatch(action(response.data.processedCustomers))
      })
      .catch(error => {

        dispatch({
          type: HANDLE_ERROR,
          error
        })

        throw new Error(error);

      })
  }
}


export function createNewMoveIn(moveIn) {

  return dispatch => {

    dispatch({
      type: SET_LOADING,
      isLoading: true
    });

    moveIn.source = 'Internal';

    return axios.post('/customermovein', moveIn)
      .then(response => {
        dispatch(createdNewMoveIn(response.data.createdMoveIn))
      })
      .catch(error => {

        dispatch({
          type: HANDLE_ERROR,
          error
        })

        throw new Error(error);

      })
  }
}