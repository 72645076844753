// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";

export const GET_PROPERTY_MANAGERS = "GET_PROPERTY_MANAGERS";
export const GET_PROPERTY_MANAGERS_RESPONSE = "GET_PROPERTY_MANAGERS_RESPONSE";
export const GET_PROPERTY_MANAGERS_FAIL = "GET_PROPERTY_MANAGERS_RESPONSE_FAIL";

export const GET_PROPERTY_MANAGER = "GET_PROPERTY_MANAGER";
export const GET_PROPERTY_MANAGER_RESPONSE = "GET_PROPERTY_MANAGER_RESPONSE";
export const GET_PROPERTY_MANAGER_RESPONSE_FAIL = "GET_PROPERTY_MANAGER_RESPONSE_FAIL";

export const POST_PROPERTY_MANAGER = "POST_PROPERTY_MANAGER";
export const POST_PROPERTY_MANAGER_RESPONSE = "POST_PROPERTY_MANAGER_RESPONSE";
export const POST_PROPERTY_MANAGER_FAIL = "POST_PROPERTY_MANAGER_FAIL";

export const PUT_PROPERTY_MANAGER = "PUT_PROPERTY_MANAGER";
export const PUT_PROPERTY_MANAGER_RESPONSE = "PUT_PROPERTY_MANAGER_RESPONSE";
export const PUT_PROPERTY_MANAGER_FAIL = "PUT_PROPERTY_MANAGER_FAIL";

export const UPDATE_PROPERTY_MANAGER_BALANCE_SUMMARY = "UPDATE_PROPERTY_MANAGER_BALANCE_SUMMARY";
export const GET_PROPERTY_MANAGER_BALANCE_SUMMARY_REQUEST = "GET_PROPERTY_MANAGER_BALANCE_SUMMARY_REQUEST";
export const GET_PROPERTY_MANAGER_BALANCE_SUMMARY_RESPONSE = "GET_PROPERTY_MANAGER_BALANCE_SUMMARY_RESPONSE";

export const UPDATE_PROPERTY_MANAGER_ACCOUNT_SUMMARY = "UPDATE_PROPERTY_MANAGER_ACCOUNT_SUMMARY";
export const GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_REQUEST = "GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_REQUEST";
export const GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_RESPONSE = "GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_RESPONSE";


export const UPDATE_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY = "UPDATE_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY";
export const GET_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY_REQUEST = "GET_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY_REQUEST";
export const GET_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY_RESPONSE = "GET_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY_RESPONSE";


export const UPDATE_PROPERTY_MANAGER_BILLING_SUMMARY = "UPDATE_PROPERTY_MANAGER_BILLING_SUMMARY";
export const GET_PROPERTY_MANAGER_BILLING_SUMMARY_REQUEST = "GET_PROPERTY_MANAGER_BILLING_SUMMARY_REQUEST";
export const GET_PROPERTY_MANAGER_BILLING_SUMMARY_RESPONSE = "GET_PROPERTY_MANAGER_BILLING_SUMMARY_RESPONSE";

export const UPDATE_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_AND_SUMMARY = "UPDATE_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_AND_SUMMARY";
export const GET_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_REQUEST = "GET_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_REQUEST";
export const GET_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_RESPONSE = "GET_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_RESPONSE";

export function updatePmAccountSummary(newSummaryData) {
  return { type: UPDATE_PROPERTY_MANAGER_ACCOUNT_SUMMARY, newSummaryData };
}

export function updatePmBalanceSummary(newSummaryData) {
  return { type: UPDATE_PROPERTY_MANAGER_BALANCE_SUMMARY, newSummaryData };
}

export function updatePmBillingSummary(newSummaryData) {
  return { type: UPDATE_PROPERTY_MANAGER_BILLING_SUMMARY, newSummaryData };
}

export function updatePmTenantAccountsAndBalanceSummary(newSummaryData) {
  return { type: UPDATE_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY, newSummaryData };
}

export function updatePmResponsibleAccountsAndBalanceSummary(newSummaryData) {
  return { type: UPDATE_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_AND_SUMMARY, newSummaryData };
}

export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_MANAGERS,
      isLoading: isLoading,
    });
    return axios
      .get("/propertymanager", {})
      .then((response) => {
        //if (validateResponse(response.data.filters, filters)) dispatch(updateSearchResults(response.data.result));
        dispatch({
          type: GET_PROPERTY_MANAGERS_RESPONSE,
          propertyManagers: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_MANAGERS_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_MANAGER,
      isLoading: isLoading,
    });
    return axios
      .get(`/propertymanager-internalapp/?uuid=${uuid}`)
      .then((response) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_RESPONSE,
          propertyManager: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getById(id, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_MANAGER,
      isLoading: isLoading,
    });
    return axios
      .get(`/property-manager/${id}`)
      .then((response) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_RESPONSE,
          propertyManager: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_RESPONSE_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function post(propertyManager, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: POST_PROPERTY_MANAGER,
      isLoading,
    });
    return axios
      .post("/propertymanager/", propertyManager)
      .then((response) => {
        dispatch({
          propertyManager: response.data,
          type: POST_PROPERTY_MANAGER_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: POST_PROPERTY_MANAGER_FAIL,
          error: error,
        });
      });
  };
}

export function put(propertyManager, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: PUT_PROPERTY_MANAGER,
      isLoading: isLoading,
    });
    return axios
      .put("/propertymanager/" + propertyManager.uuid, propertyManager)
      .then((response) => {
        dispatch({
          propertyManager: response.data,
          type: PUT_PROPERTY_MANAGER_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: PUT_PROPERTY_MANAGER_FAIL,
          error: error,
        });
      });
  };
}


export function getPmAccountsSummary(pmUuid, propUuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/pm-account-only-summary/" + pmUuid + "/" + propUuid)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updatePmAccountSummary(response.data));
          dispatch({
            type: GET_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getPmBillingSummary(pmUuid, propUuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_MANAGER_BILLING_SUMMARY_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/propertymanageraccountbillsummary/" + pmUuid + "/" + propUuid)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updatePmBillingSummary(response.data));
          dispatch({
            type: GET_PROPERTY_MANAGER_BILLING_SUMMARY_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_PROPERTY_MANAGER_BILLING_SUMMARY_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_BILLING_SUMMARY_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getPmTenantAccountsAndSummaryPaginated(pmUuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/get-accounts-filtered/?page=0&pageLimit=0&pmUuid=" + pmUuid)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updatePmBalanceSummary(response.data.summary));
          dispatch({
            type: GET_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_TENNANT_ACCOUNTS_AND_SUMMARY_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getPmResponsibleAccountsAndSummaryPaginated(pmUuid, page, pageLimit, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_MANAGER_BALANCE_SUMMARY_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get(`/get-accounts-filtered/?page=${page}&pageLimit=${pageLimit}&pmUuid=${pmUuid}`)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updatePmResponsibleAccountsAndBalanceSummary(response.data));
          dispatch({
            type: GET_PROPERTY_MANAGER_BALANCE_SUMMARY_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_PROPERTY_MANAGER_BALANCE_SUMMARY_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_BALANCE_SUMMARY_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getPmAccountSummary(pmUuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/get-accounts-filtered/?page=0&pageLimit=0&status=Active&pmUuid=" + pmUuid)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updatePmAccountSummary(response.data.summary));
          dispatch({
            type: GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}