// Imports
import axios from "axios";
import { APP_URL_API, PORT } from "./env";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Accept"] = "*";
axios.defaults.headers.post["Content-Type"] = "application/json";

const isDebugMode = false;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && !isDebugMode) {
      const refreshToken = window.localStorage.getItem("refreshToken");
      if (
        refreshToken != undefined &&
        error.response.config.url != "/get-refresh-token" && error.response.config.url != "/verify-token"
      ) {
        axios
          .post("/get-refresh-token", { refresh_token: refreshToken })
          .then((response) => {
            if (response) {
              window.localStorage.setItem("token", response.data.token);
              window.location.reload();
            }
          });
      } else if (error.response.config.url == "/verify-token") {
        //nothing
      }
      else 
      {
        window.localStorage.clear();
        window.location = "/";
      }
    }
    return Promise.reject(error);
  }
);
