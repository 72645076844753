// Imports
import axios from 'axios'
import cookie from 'js-cookie'
import { setSnackbar } from "../../snackbar/api/snackbar";
import { GET, POST, PUT, DEL } from "../../genericAPI/api/actions";

require('../../../setup/config/apiConfig');

// Actions Types
export const LOGIN_REQUEST = 'AUTH/LOGIN_REQUEST'
export const LOGIN_RESPONSE = 'AUTH/LOGIN_RESPONSE'

export const RESET_REQUEST = 'AUTH/RESET_REQUEST'
export const RESET_RESPONSE = 'AUTH/RESET_RESPONSE'

export const RESET_PASSWORD_REQUEST = 'AUTH/RESET__PASSWORD_REQUEST'
export const RESET_PASSWORD_RESPONSE = 'AUTH/RESET__PASSWORD_RESPONSE'

export const REFRESH_REQUEST = 'AUTH/REFRESH_REQUEST'
export const REFRESH_RESPONSE = 'AUTH/REFRESH_RESPONSE'

export const HYDRATE_REQUEST = 'USER/HYDRATE_REQUEST'
export const HYDRATE_RESPONSE = 'USER/HYDRATE_RESPONSE'

export const GET_ALL_TRANSACTIONS_REQUEST = 'USER/GET_ALL_DATA_REQUEST'
export const GET_ALL_TRANSACTIONS_RESPONSE = 'USER/GET_ALL_DATA_RESPONSE'
export const UPDATE_USER = 'USER/UPDATE_USER'
export const SET_USER = 'AUTH/SET_USER'
export const LOGOUT = 'AUTH/LOGOUT'
export const REGISTER_NEW_USER_REQUEST = 'AUTH/REGISTER_NEW_USER_REQUEST'
export const REGISTER_NEW_USER_RESPONSE = 'AUTH/REGISTER_NEW_USER_RESPONSE'
export const UPDATE_USER_REQUEST = 'USER/UPDATE_USER_REQUEST'
export const UPDATE_USER_RESPONSE = 'USER/UPDATE_USER_RESPONSE'
export const UPDATE_USER_FAILURE = 'USER/UPDATE_USER_FAILURE'



export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_RESPONSE = "UPDATE_USER_PROFILE_RESPONSE";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const UPDATE_PASSWORD_REQUEST = 'USER/UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_RESPONSE = 'USER/UPDATE_PASSWORD_RESPONSE'

export const GET_USER_PAYMENT_METHODS_REQUEST = 'USER/GET_USER_PAYMENT_METHODS_REQUEST'
export const GET_USER_PAYMENT_METHODS_RESPONSE = 'USER/GET_USER_PAYMENT_METHODS_RESPONSE'


// Set a user after login or using localStorage token and as the default access header in all requests
export function setUserAndToken(token, user) {
  if (token) {
    axios.defaults.headers.common['x-access-limit'] = 30;
    axios.defaults.headers.common['x-access-token'] = token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
  return { type: SET_USER, user }
}

export function setUser(user) {
  return { type: SET_USER, user }
}


function userHasMultipleAccounts(user) {
  let toReturn = false;
  let TotalAccounts = 0;
  if (user) {
    if (user.details.overview) {
      TotalAccounts = TotalAccounts + user.details.overview.pmAccounts.length;
      if (user.details.overview.accounts != null) {
        TotalAccounts = TotalAccounts + user.details.overview.accounts.length;
      }
    }
  }
  if (TotalAccounts > 1) {
    toReturn = true;
  }
  return toReturn;
}



// Set updated user info in localStorage and cookie
export function updateUserLocalStorage(user) {
  // Update token
  window.localStorage.setItem('user', JSON.stringify(user))
}

// Set updated user with new incoming payments in localStorage and state
export function updateUserStateAndLocalStorage(newData) {
  return {
    type: UPDATE_USER,
    newData
  };
}

// Get User from local storage
export function getCurrentUserFromLocalStorage() {
  // Update token
  return JSON.parse(window.localStorage.getItem('user'));
}

// Set user token and info in localStorage and cookie
export function loginSetUserLocalStorageAndCookie(token, user, refreshToken) {
  // Update token
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('user', JSON.stringify(user))
  if (refreshToken)
    window.localStorage.setItem('refreshToken', refreshToken);

  // Set cookie for SSR
  cookie.set('auth', { token, user }, { path: '/' }, { expires: 1 })
}


// Login a user using credentials
export function login(loginBody) {
  return dispatch => {
    dispatch({
      type: LOGIN_REQUEST,
      isLoading: true
    });
    return axios.post(`/customer-user-login`, loginBody)
      .then(response => {
        let error = '';
        if (response.data.error) {
          error = response.data.errors[0].message
        } else if (response.data.token !== '') {
          const token = response.data.token;
          const refreshToken = response.data.refreshToken;
          const user = response.data.user;
          loginSetUserLocalStorageAndCookie(token, user, refreshToken);
          dispatch(setUserAndToken(token, user));
        }
        dispatch({
          type: LOGIN_RESPONSE,
          isLoading: false,
          error: error
        })
      })
      .catch(error => {
        dispatch(setSnackbar(true, "error", "Unable to Login. Verify credentials"));
        if (error.response) {
          dispatch({
            type: LOGIN_RESPONSE,
            error: error.response.data,
            isLoading: false,
          })
        }
      });
  }
}


export function resetPasswordRequest(email) {
  return dispatch => {
    dispatch({
      type: LOGIN_REQUEST,
      isLoading: true
    });
    var loginBody = {
      'email': email,
    };
    return axios.post(`/customer/password-reset`, loginBody)
      .then(response => {
        let error = '';
        if (response.status == 200) {
          dispatch(setSnackbar(true, "success", "Check your mailbox for a rest link."));
        }
        else {
          dispatch(setSnackbar(true, "error", "Unable to send new password link"));

        }
        dispatch({
          type: RESET_RESPONSE,
          isLoading: false,
          error: error
        })
      })
      .catch(error => {
        dispatch(setSnackbar(true, "error", "Unable to send new password link"));
        if (error.response) {
          dispatch({
            type: RESET_RESPONSE,
            error: error.response.data,
            isLoading: false,
          })
        }
      });
  }
}


export function passwordReset(token, password) {
  return dispatch => {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
      isLoading: true
    });
    var loginBody = {
      'token': token,
      'password': password
    };
    return axios.post(`/password-reset`, loginBody)
      .then(response => {
        let error = '';
        if (response.status == 200) {
          dispatch(setSnackbar(true, "success", "Password has been reset."));
          window.location = "/login"
        }
        else {
          dispatch(setSnackbar(true, "error", "Unable to reset password. Try again."));

        }
        dispatch({
          type: RESET_PASSWORD_RESPONSE,
          isLoading: false,
          error: error
        })
      })
      .catch(error => {
        dispatch(setSnackbar(true, "error", "Unable to reset password"));
        if (error.response) {
          dispatch({
            type: RESET_PASSWORD_RESPONSE,
            error: error.response.data,
            isLoading: false,
          })
        }
      });
  }
}

// Log out user and remove token from localStorage
export function logout() {
  return dispatch => {
    dispatch({
      type: LOGOUT,
    })
    logoutUnsetUserLocalStorageAndCookie()
  }
}

// Unset user token and info in localStorage and cookie
export function logoutUnsetUserLocalStorageAndCookie() {
  // Remove token
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('refreshToken')
  // Remove cookie
  cookie.remove('auth');
}


// Login a user using credentials
export function getRefreshToken(refreshToken) {
  return axios.post(`/get-refresh-token`, { refresh_token: refreshToken })
    .then(response => {
      let error = '';
      if (response.data.error) {
        error = response.data.errors[0].message
      } else if (response.data.token !== '') {
        const token = response.data.token;
        const user = response.data.user;
        setUserAndToken(token, user);
        loginSetUserLocalStorageAndCookie(token, user, refreshToken);
        window.location.reload();
      }

    })
    .catch(error => {
      window.location = '/login';
    });
}

export function updateUserProfile(user) {
  window.localStorage.setItem('user', JSON.stringify(user));
  const outGoingData = { "email": user.email, "firstName": user.firstName, "lastName": user.lastName, "phone": user.phone }
  return PUT("user", user.uuid, outGoingData, UPDATE_USER_PROFILE_REQUEST, UPDATE_USER_PROFILE_RESPONSE, UPDATE_USER_PROFILE_FAILURE);
}

//Hydrate account (Only used to get properties and other customer details)
export function hydrateAccount(userUUID, isLoading = true) {
  return dispatch => {
    dispatch({
      type: HYDRATE_REQUEST,
      isLoading
    });
    return axios.get('/account/hydrate/' + userUUID)
      .then(response => {
        let error = '';
        //console.log(response);
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
          ////console.log("Errors Found!");
        }
        dispatch(updateUserStateAndLocalStorage(response.data));
        dispatch({
          type: HYDRATE_RESPONSE,
          error: error
        })
      })
      .catch(error => {
        //console.log(error);
        dispatch({
          type: HYDRATE_RESPONSE,
          error: error
        })
      })
  }
}

//{email: varchar, firstName: varchar, lastName: varchar, phone: int, uuid: varchar} in body
export function syncAccountAndUserInformationDetails(account, userUuid) {
  const outGoingData = { "email": account.email, "firstName": account.firstName, "lastName": account.lastName, "phone": account.phone1, "uuid": userUuid }
  return dispatch => {
    dispatch({
      type: UPDATE_USER_REQUEST,
      isLoading: true
    });
    return axios.post('/customer-portal/updateCustomerProfileDetails', outGoingData)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }
        dispatch({
          type: UPDATE_USER_RESPONSE,
          error: error
        })
      })
      .catch(error => {
        dispatch({
          type: UPDATE_USER_RESPONSE,
          error: error
        })
      })
  }
}



export function register(newUser) {
  //console.log("New User in Actions:");
  //console.log(newUser);
  return dispatch => {
    dispatch({
      type: REGISTER_NEW_USER_REQUEST,
      error: null,
      isLoading: true
    })
    return axios.get("/").then(response => {
      let error = '';
      dispatch({
        type: REGISTER_NEW_USER_RESPONSE,
        error: error
      })
    });
  }
}

// Update an existing users password. Seperate route to logout after completion
export function updateUserPassword(newPasswordInfo) {
  //console.log("New Password Data:");
  //console.log(newPasswordInfo);
  return dispatch => {
    dispatch({
      type: UPDATE_USER_REQUEST,
      error: null,
      isLoading: true
    })
    return axios.post("/customer-portal/updateCustomerPasswordIfValidLoginCredentials", newPasswordInfo).then(response => {
      let error = '';
      dispatch({
        type: UPDATE_USER_RESPONSE,
        error: error
      })
    }).catch((err) => {
      dispatch({
        type: UPDATE_USER_FAILURE,
        error: err.response.data.message
      })
    });
  }
}