
import {
  FETCHED_MOVES,
  SET_LOADING,
  HANDLE_ERROR,
  DELETE_MOVE_IN,
  DELETE_MOVE_OUT,
  UPDATED_MOVE_INS,
  UPDATED_MOVE_OUTS,
  EDITED_MOVE_IN,
  EDITED_MOVE_OUT,
  PROCESSED_MOVE_INS,
  PROCESSED_MOVE_OUTS,
  CREATED_MOVE_IN,
  SET_TAB_STATE
} from './actions'

// Initial State
const MovesInitialState = {
  isLoading: false,
  error: null,
  data: {
    moveIns: [],
    moveOuts: [],
    tabState: 'Current/Past'
  }
}

// State
export default (state = MovesInitialState, action) => {

  switch (action.type) {

    case SET_TAB_STATE:
      let newTabState = Object.assign({}, state.data, { tabState: action.tabName });
      return { ...state, data: newTabState, isLoading: false,  };

    case FETCHED_MOVES:
      let moveInsWithSetHold = action.moves.moveIns.map(moveIn => {
        if (moveIn.prevReadBilled == 0) {
          moveIn.onHold = 1;
        }

        return moveIn
      });
      let moveOutsWithSetHold = action.moves.moveOuts.map(moveOut => {
        if (moveOut.prevReadBilled == 0) {
          moveOut.onHold = 1;
        }

        return moveOut
      });
      const movesData = Object.assign({}, state.data, { moveIns: moveInsWithSetHold, moveOuts: moveOutsWithSetHold });
      return { ...state, data: movesData, isLoading: false };

    case SET_LOADING:

      return Object.assign({}, state, { isLoading: action.isLoading });

    case HANDLE_ERROR:

      return Object.assign({}, state, { error: action.error, isLoading: false });

    case DELETE_MOVE_IN:

      let ind = state.data.moveIns.findIndex(elem => elem.uuid == action.uuid);
      let newMoveInsCopy = [...state.data.moveIns.slice(0, ind), ...state.data.moveIns.slice(ind + 1)];
      let moveInsData = Object.assign({}, state.data, { moveIns: newMoveInsCopy });
      return { ...state, data: moveInsData, isLoading: false }

    case DELETE_MOVE_OUT:

      let moind = state.data.moveOuts.findIndex(elem => elem.uuid == action.uuid);
      let newMoveOutsCopy = [...state.data.moveOuts.slice(0, moind), ...state.data.moveOuts.slice(moind + 1)];
      let moveOutsData = Object.assign({}, state.data, { moveOuts: newMoveOutsCopy });
      return { ...state, data: moveOutsData, isLoading: false }


    case UPDATED_MOVE_INS:

      const newMoveIns = [];
      state.data.moveIns.forEach(moveIn => {
        let didMatch = false;
        action.uuids.forEach(uuid => {

          if (uuid == moveIn.uuid) {
            let keyToUpdate = Object.keys(action.keyValPair)[0];
            let newMove = Object.assign({}, moveIn, { [keyToUpdate]: action.keyValPair[keyToUpdate] })
            newMoveIns.push(newMove);
            didMatch = true;
          }
        })
        if (!didMatch)
          newMoveIns.push(moveIn);
      })

      let updatedMoveInsData = Object.assign({}, state.data, { moveIns: newMoveIns });
      return { ...state, data: updatedMoveInsData, isLoading: false };



    case UPDATED_MOVE_OUTS:
      const newMoveOuts = [];
      state.data.moveOuts.forEach(moveOut => {
        action.uuids.forEach(uuid => {

          if (uuid == moveOut.uuid) {
            let keyToUpdate = Object.keys(action.keyValPair)[0];
            let newMove = Object.assign({}, moveOut, { [keyToUpdate]: action.keyValPair[keyToUpdate] })
            newMoveOuts.push(newMove);
          }
          else
            newMoveOuts.push(moveOut);

        })
      })

      let updatedMoveOutsData = Object.assign({}, state.data, { moveOuts: newMoveOuts });
      return { ...state, data: updatedMoveOutsData, isLoading: false };



    case EDITED_MOVE_IN:
      const newMoveInsWithEdit = [];
      state.data.moveIns.forEach(moveIn => {

        if (moveIn.uuid == action.originalObj.uuid) {
          let moveWithEdits = Object.assign({}, action.originalObj, action.editObj);
          newMoveInsWithEdit.push(moveWithEdits);
        }
        else
          newMoveInsWithEdit.push(moveIn);

      })

      let editedMoveIns = Object.assign({}, state.data, { moveIns: newMoveInsWithEdit });
      return { ...state, data: editedMoveIns, isLoading: false };


    case EDITED_MOVE_OUT:
      const newMoveOutsWithEdit = [];
      state.data.moveOuts.forEach(moveOut => {

        if (moveOut.uuid == action.originalObj.uuid) {
          let moveOutWithEdits = Object.assign({}, action.originalObj, action.editObj);
          newMoveOutsWithEdit.push(moveOutWithEdits);
        }
        else
          newMoveOutsWithEdit.push(moveOut);

      })

      let editedMoveOuts = Object.assign({}, state.data, { moveOuts: newMoveOutsWithEdit });
      return { ...state, data: editedMoveOuts, isLoading: false };


    case PROCESSED_MOVE_INS:
      const newMoveInsWithProcessed = [];

      state.data.moveIns.forEach(moveIn => {
        let didMatch = false;
        action.uuids.forEach(uuid => {

          if (moveIn.uuid == uuid) {
            let newPMoveIn = Object.assign({}, moveIn, { processed: 1 });
            newMoveInsWithProcessed.push(newPMoveIn);
            didMatch = true;
          }
        })

        if (!didMatch)
          newMoveInsWithProcessed.push(moveIn);

      })

      let processedMoveIns = Object.assign({}, state.data, { moveIns: newMoveInsWithProcessed });
      return { ...state, data: processedMoveIns, isLoading: false };



    case CREATED_MOVE_IN:
      let moveInsWithNew = state.data.moveIns.concat([action.moveIn]);
      let moveInsWithNewState = Object.assign({}, state.data, { moveIns: moveInsWithNew });
      return {...state, data: moveInsWithNewState, isLoading: false };

    default:
      return state;
  }
}