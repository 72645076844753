import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';

// Scroll up button
import ScrollUpButton from "react-scroll-up-button";

//Import Switcher
import ThemeSwitcher from "./ThemeSwitcher";

//Import Icons
import FeatherIcon from 'feather-icons-react';

// Layout Components
const Topbar = React.lazy(() => import('./Topbar'));
const TopbarLimited = React.lazy(() => import('./TopBarLimited'));
const NavbarPage = React.lazy(() => import('../../pages/Saas Onepage/NavbarPage'));
const Footer = React.lazy(() => import('./Footer'));
const FooterWithoutMenuLightSocialOnly = React.lazy(() => import('./FooterWithoutMenuLightSocialOnly'));

const CustomDot = () => {
  return (
    <i><FeatherIcon icon="arrow-up" className="icons" /></i>
  );
};

class Layout extends Component {

  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        {
          (this.props.location.pathname.includes("/main-menu") || this.props.location.pathname.includes("/overview")) ?
            <TopbarLimited />
            :
            <Topbar />
        }
        {/*combined height of 2 footers (280px), temporarily hardcoded*/}
        <div style={{ minHeight: 'calc(100vh - 280px)' }}>
          {this.props.children}
        </div>

        {(() => {
          if (this.props.location.pathname.includes("/index-marketing") || this.props.location.pathname.includes("/index-digital-agency") || this.props.location.pathname.includes("/index-modern-business") || this.props.location.pathname.includes("/index-services") || this.props.location.pathname.includes("/index-job")) {
            return (
              <Footer isLight={true} />
            )
          }
          else if (this.props.location.pathname.includes("/index-portfolio") || this.props.location.pathname.includes("/page-contact-two")) {
            return (
              <FooterWithoutMenuLightSocialOnly class="border-0 bg-light text-dark" />
            )
          }
          else if (this.props.location.pathname.includes("/index-personal") || this.props.location.pathname.includes("/helpcenter-overview") || this.props.location.pathname.includes("/page-invoice")) {
            return (
              <FooterWithoutMenuLightSocialOnly class="" />
            )
          }
          /*else if ( this.props.location.pathname.includes("/main-menu")){
            return <></>
          } */
          else {
            return (
              <Footer />
            )
          }
        })()}



        {/* <div className="btn btn-icon btn-soft-primary back-to-top"> */}
        {/* scrollup button */}
        <ScrollUpButton ContainerClassName="classForContainer" style={{ height: 36, width: 36 }} TransitionClassName="classForTransition">
        </ScrollUpButton>
        {/* </div> */}

        {/* theme switcher */}


      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
