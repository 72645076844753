// App Imports
import { isEmpty } from "../../../setup/helpers";
import {
  ACCOUNTS_OBJECT_RESET,
  HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST,
  HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
  HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE,
  UPDATE_FILTERS,
  UPDATE_ACCOUNTS_FOUND,
  HANDLE_ACCOUNTS_SEARCH_REQUEST,
  HANDLE_ACCOUNTS_SEARCH_RESPONSE,
  HANDLE_ACCOUNTS_SEARCH_FAILURE,
  UPDATE_ACCOUNTS_FOUND_AND_SUMMARY,
  HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_REQUEST,
  HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_RESPONSE,
  HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_FAILURE,
  UPDATE_CHILD_ACCOUNTS_FOUND_AND_SUMMARY,
  SET_USER_ACCOUNT_COUNT
} from "./actions";
const today = new Date();
// Initial State
const accountsInitialState = {
  isLoading: false,
  childAccountSearchIsLoading: true,
  error: null,
  accountCount: 0,
  pmAccountCount: 0,
  tenantAccountCount: 0,
  data: {
    // For the current accounts cached locally
    accountPreviews: {
      items: [],
      toDelete: [],
      toUpdate: [],
    },
    childAccountsFound: {

    },
    previewsSummary: null,
    // The Current Account Management Filters(Default is by name with no calue):
    filters: [{ field: "account", target: "" }],
    range: { start: 0, end: 25 }, // Gets the first 25 accounts in the list
  },
};

// State
export default (state = accountsInitialState, action) => {
  switch (action.type) {
    case ACCOUNTS_OBJECT_RESET:
      return Object.assign({}, accountsInitialState);

    case UPDATE_ACCOUNTS_FOUND:
      let updatedAccountPreviews = {
        items: action.newAccountResults,
        toDelete: [],
        toUpdate: [],
      };
      var newStateData = state.data;
      newStateData.accountPreviews = updatedAccountPreviews;
      return {
        ...state,
        data: newStateData,
      };
    case ACCOUNTS_OBJECT_RESET:
      return Object.assign({}, accountsInitialState);

    case SET_USER_ACCOUNT_COUNT:
      return {
        ...state,
        accountCount: action.accountCount,
        pmAccountCount: action.pmAccountCount,
        tenantAccountCount: action.tenantAccountCount,
      };

    case UPDATE_CHILD_ACCOUNTS_FOUND_AND_SUMMARY:
      let newData = state.data;
      newData.childAccountsFound = action.newAccountResults;
      return {
        ...state,
        data: newData,
      };
    case UPDATE_ACCOUNTS_FOUND_AND_SUMMARY:
      var newStateData = state.data;
      if (action.resetCache) {
        let accountPreviews = {
          items: action.searchResponse.items,
          toDelete: [],
          toUpdate: [],
        };
        newStateData.accountPreviews = accountPreviews;
      }
      else {
        let accountPreviews = {
          items: newStateData.accountPreviews.items.concat(action.searchResponse.items),
          toDelete: [],
          toUpdate: [],
        };
        newStateData.accountPreviews = accountPreviews;
      }
      if (action.isBaseGroup) {
        newStateData.previewsSummary = action.searchResponse.summary;
      }
      return {
        ...state,
        data: newStateData,
      };
    case UPDATE_FILTERS:
      var newStateData = Object.assign({}, state.data);
      newStateData.filters = action.newFilters;
      return {
        ...state,
        data: newStateData,
      };
    case HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case HANDLE_ACCOUNTS_SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };



    case HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        childAccountSearchIsLoading: action.isLoading,
      };
    case HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_RESPONSE:
      return {
        ...state,
        error: action.error,
        childAccountSearchIsLoading: false,
      };
    case HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_FAILURE:
      return {
        ...state,
        error: null,
        childAccountSearchIsLoading: false,
      };



    case HANDLE_ACCOUNTS_SEARCH_RESPONSE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
