// Imports
import { compose, combineReducers } from 'redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

// App Imports
import user from '../modules/user/api/state'
import bills from '../modules/bill/api/state'
import paymentMethods from '../modules/paymentMethods/api/state'
import apl from "../modules/apl/api/state";
import meterReadingsUpload from "../modules/readings-uploads/api/state";
import accountsSearch from "../modules/accountsSearch/api/state";
import account from "../modules/account/api/state";
import payments from "../modules/payments/api/state";
import userProfiles from "../modules/userProfiles/api/state";
import delinquencies from "../modules/delinquencies/api/state";
import disconnects from "../modules/disconnects/api/state";
import units from "../modules/unit/api/state";
import meters from "../modules/meter/api/state";
import propertyManagers from "../modules/propertyManager/api/state";
import moves from "../modules/moves/api/state";
import property from "../modules/property/api/state";
import workOrders from "../modules/workOrders/api/state";
import propertyUtility from "../modules/propertyUtility/api/state";

// App Reducer
const appReducer = combineReducers({
  user,
  paymentMethods,
  delinquencies,
  disconnects,
  apl,
  accountsSearch,
  account,
  bills,
  payments,
  userProfiles,
  meterReadingsUpload,
  moves,
  units,
  meters,
  propertyManagers,
  propertyUtility,
  property,
  workOrders
})

// Root Reducer
export const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined
  }

  return appReducer(state, action)
}

// Load initial state from server side
let initialState;
if (typeof window !== 'undefined') {
  initialState = window.__INITIAL_STATE__
  delete window.__INITIAL_STATE__
}

// Store
export const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(thunk),
  )
)
