import {
  RESET,
  GET_PROPERTY_MANAGERS,
  GET_PROPERTY_MANAGERS_RESPONSE,
  GET_PROPERTY_MANAGERS_RESPONSE_FAIL,
  GET_PROPERTY_MANAGER,
  GET_PROPERTY_MANAGER_RESPONSE,
  GET_PROPERTY_MANAGER_RESPONSE_FAIL,
  POST_PROPERTY_MANAGER,
  POST_PROPERTY_MANAGER_RESPONSE,
  POST_PROPERTY_MANAGER_FAIL,
  PUT_PROPERTY_MANAGER,
  PUT_PROPERTY_MANAGER_RESPONSE,
  PUT_PROPERTY_MANAGER_FAIL,

  // Pm Summary states
  UPDATE_PROPERTY_MANAGER_BALANCE_SUMMARY,
  GET_PROPERTY_MANAGER_BALANCE_SUMMARY_REQUEST,
  GET_PROPERTY_MANAGER_BALANCE_SUMMARY_RESPONSE,
  UPDATE_PROPERTY_MANAGER_ACCOUNT_SUMMARY,
  GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_REQUEST,
  GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_RESPONSE,
  UPDATE_PROPERTY_MANAGER_BILLING_SUMMARY,
  GET_PROPERTY_MANAGER_BILLING_SUMMARY_REQUEST,
  GET_PROPERTY_MANAGER_BILLING_SUMMARY_RESPONSE,
  UPDATE_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_AND_SUMMARY,
  GET_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_REQUEST,
  GET_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_RESPONSE

} from "./actions";

const initialState = {
  propertyManagerIsLoading: true,
  // Pm Summary data calls and isLoading states
  propertyManagerBalanceSummaryIsLoading: false,
  propertyManagerBalanceSummary: null,
  propertyManagerAccountSummaryIsLoading: false,
  propertyManagerAccountSummary: null,
  propertyManagerBillingSummaryIsLoading: false,
  propertyManagerBillingSummary: null,
  propertyManagerResponsibleAccounts: [],
  propertyManagerResponsibleAccountsIsLoading: false,
  propertyManagerResponsibleSummary: [] ,

  loadedBillingHistoryData: [{
    label: 'Vacant Unit Accounts',
    data: []
  },
  {
    label: 'Occupied Unit Accounts',
    data: []
  },
  {
    label: 'Common Area Accounts',
    data: []
  }],

  putPropertyManagerError: null,
  postPropertyManagerError: null,
  isLoading: false,
  error: null,
  data: {
    propertyManagersList: {
      items: [],
    },
    propertyManager: {},
    searchQuery: null,
    range: { start: 0, end: 25 },
  },
};

// State
export default (state = initialState, action) => {
  var data = state.data;
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case GET_PROPERTY_MANAGERS:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_PROPERTY_MANAGERS_RESPONSE:
      data.propertyManagersList.items = action.propertyManagers;
      return {
        ...state,
        data: data,
        error: action.error,
        isLoading: false,
      };

    // PM balance summary
    case UPDATE_PROPERTY_MANAGER_BALANCE_SUMMARY:
      return {
        ...state,
        propertyManagerBalanceSummary: action.newSummaryData
      };
    case GET_PROPERTY_MANAGER_BALANCE_SUMMARY_REQUEST:
      return {
        ...state,
        error: null,
        propertyManagerBalanceSummaryIsLoading: action.isLoading,
      };
    case GET_PROPERTY_MANAGER_BALANCE_SUMMARY_RESPONSE:
      return {
        ...state,
        error: action.error,
        propertyManagerBalanceSummaryIsLoading: false,
      };


    // PM account summary
    case UPDATE_PROPERTY_MANAGER_ACCOUNT_SUMMARY:
      return {
        ...state,
        propertyManagerAccountSummary: action.newSummaryData
      };
    case GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_REQUEST:
      return {
        ...state,
        error: null,
        propertyManagerAccountSummaryIsLoading: action.isLoading,
      };
    case GET_PROPERTY_MANAGER_ACCOUNT_SUMMARY_RESPONSE:
      return {
        ...state,
        error: action.error,
        propertyManagerAccountSummaryIsLoading: false,
      };

    case UPDATE_PROPERTY_MANAGER_RESPONSIBLE_ACCOUNTS_AND_SUMMARY:
      return {
        ...state,
        propertyManagerResponsibleSummary: action.newSummaryData.summary,
        propertyManagerResponsibleAccounts: action.newSummaryData.items,
        propertyManagerResponsibleSummaryIsLoading: false
      }
    // PM billing summary
    case UPDATE_PROPERTY_MANAGER_BILLING_SUMMARY:
      if (action.newSummaryData.length > 0) {
        var loadedData = [{
          label: 'Vacant Unit Accounts',
          data: []
        },
        {
          label: 'Occupied Unit Accounts',
          data: []
        },
        {
          label: 'Common Area Accounts',
          data: []
        }];
        action.newSummaryData.map((fiscalMonth, index) => {
          loadedData[0].data.push({ x: index, y: fiscalMonth.activePmVacantUnitUsage });
          loadedData[1].data.push({ x: index, y: fiscalMonth.activeTenantOccupiedUsage });
          loadedData[2].data.push({ x: index, y: fiscalMonth.activeCommonAreaUsage });
        })
      }
      return {
        ...state,
        loadedBillingHistoryData: loadedData,
        propertyManagerBillingSummary: action.newSummaryData
      };
    case GET_PROPERTY_MANAGER_BILLING_SUMMARY_REQUEST:
      return {
        ...state,
        error: null,
        propertyManagerBillingSummaryIsLoading: action.isLoading,
      };
    case GET_PROPERTY_MANAGER_BILLING_SUMMARY_RESPONSE:
      return {
        ...state,
        error: action.error,
        propertyManagerBillingSummaryIsLoading: false,
      };


    case GET_PROPERTY_MANAGER:
      return {
        ...state,
        error: null,
        propertyManagerIsLoading: action.isLoading,
      };
    case GET_PROPERTY_MANAGER_RESPONSE:
      data.propertyManager = action.propertyManager;
      return {
        ...state,
        data: data,
        error: action.error,
        propertyManagerIsLoading: false,
      };
    case POST_PROPERTY_MANAGER:
      return {
        ...state,
        error: null,
        propertyManagerIsLoading: action.isLoading,
      };
    case POST_PROPERTY_MANAGER_RESPONSE:
      data.propertyManager = action.propertyManager;
      return {
        ...state,
        data: data,
        error: action.error,
        propertyManagerIsLoading: false,
      };
    case POST_PROPERTY_MANAGER_FAIL:
      return {
        ...state,
        data: data,
        postPropertyManagerError: action.error,
      };
    case PUT_PROPERTY_MANAGER:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };
    case PUT_PROPERTY_MANAGER_RESPONSE:
      data.propertyManager = action.propertyManager;
      return {
        ...state,
        data: data,
        isLoading: false,
        putPropertyManagerError: action.error,
      };
    case PUT_PROPERTY_MANAGER_FAIL:
      return {
        ...state,
        data: data,
        isLoading: false,
        putPropertyManagerError: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};
