import React, { Component, Suspense, useState } from 'react';
import Layout from './components/Layout/';
import { Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { store } from './setup/store'
import { createStore, applyMiddleware } from 'redux'

// Import Css
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';
import './assets/css/colors/default.css';

// Include Routes 
import routes from './routes';
//Custom Components
import RoutePrivate from './modules/auth/RoutePrivate';

const existingTokens = JSON.parse(localStorage.getItem("tokens"));


function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component { 
    render() {
      return <Layout>
        <WrappedComponent></WrappedComponent>
      </Layout>
    }
  };
}

const App = ()=> {
  const Loader = () => {
    return (
      <div id="preloader">
          <div id="status">
              <div className="spinner">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
              </div>
          </div>
      </div>
    );
  }
    return (
      <React.Fragment>  
          <Router>
            <Suspense fallback = {Loader()} >
                <Switch>
                {routes.map((route, idx) =>
                    route.isWithoutLayout ?
                      route.auth?
                      <RoutePrivate path={route.path} exact={route.exact} component={route.component} key={idx} />
                      :
                      <Route path={route.path} exact={route.exact} component={route.component} key={idx} />
                    :
                      route.auth?
                      <RoutePrivate path={route.path} exact component={withLayout(route.component)} key={idx} />
                      :
                      <Route path={route.path} exact={route.exact} component={withLayout(route.component)} key={idx} />
                )}
                </Switch>
              </Suspense>
          </Router>
      </React.Fragment>
    );
}

export default withRouter(App);