// App Imports
import { isEmpty } from "../../../setup/helpers";
import {
  DISCONNECT_GET_ACCOUNT_DISCONNECTS_REQUEST,
  DISCONNECT_GET_ACCOUNT_DISCONNECTS_RESPONSE,
  DISCONNECT_GET_ACCOUNT_DISCONNECTS_FAILURE,
  DISCONNECT_ACCOUNT_DISCONNECTS_RESET,
  DISCONNECT_UPDATE_NEW_ACCOUNT_DISCONNECT_IN_PROGRESS,
  SET_DISCONNECT_LIST,
} from "./actions";

const defaultDateValue = "01-01-1900";
// Initial State
const disconnectsInitialState = {
  isLoading: true,
  error: null,
  data: [],
  defaultDateValue: defaultDateValue,
  newDisconnect: {
    accountId: -1,
    notes: "",
    disconnectAmt: 0,
  },
  searchQuery: null,
};

// State
export default (state = disconnectsInitialState, action) => {
  switch (action.type) {
    case SET_DISCONNECT_LIST:
      return {
        ...state,
        data: action.data,
      };
    case DISCONNECT_GET_ACCOUNT_DISCONNECTS_REQUEST:
      return {
        ...state,
        isLoading: action.isLoading,
        error: null,
      };

    case DISCONNECT_GET_ACCOUNT_DISCONNECTS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: action.data,
      };

    case DISCONNECT_GET_ACCOUNT_DISCONNECTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case DISCONNECT_UPDATE_NEW_ACCOUNT_DISCONNECT_IN_PROGRESS:
      // Merges the old and new property objects together
      const newDisconnectUpdated = Object.assign(
        {},
        state.newDisconnect,
        action.newDisconnectData
      );
      return {
        ...state,
        newDisconnect: newDisconnectUpdated,
      };
    case DISCONNECT_ACCOUNT_DISCONNECTS_RESET:
      return Object.assign({}, disconnectsInitialState);

    default:
      return state;
  }
};
