// Imports
import axios from "axios";
import cookie from "js-cookie";
import moment from "moment";

require("../../../setup/config/apiConfig");

// Actions Types
export const ACCOUNTS_OBJECT_RESET = "ACCOUNTS_OBJECT_RESET";
export const UPDATE_ACCOUNTS_FOUND = "UPDATE_ACCOUNTS_FOUND";
export const UPDATE_ACCOUNTS_FOUND_AND_SUMMARY = "UPDATE_ACCOUNTS_FOUND_AND_SUMMARY";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_SELECTED_ACCOUNT = "UPDATE_SELECTED_ACCOUNT";

export const HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST =
  "HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST";
export const HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE =
  "HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE";
export const HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE =
  "HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE";


export const HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_REQUEST =
  "HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_REQUEST";
export const HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_RESPONSE =
  "HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_RESPONSE";
export const HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_FAILURE =
  "HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_FAILURE";
export const UPDATE_CHILD_ACCOUNTS_FOUND_AND_SUMMARY =
  "UPDATE_CHILD_ACCOUNTS_FOUND_AND_SUMMARY";


export const HANDLE_ACCOUNTS_SEARCH_REQUEST =
  "HANDLE_ACCOUNTS_SEARCH_REQUEST";
export const HANDLE_ACCOUNTS_SEARCH_RESPONSE =
  "HANDLE_ACCOUNTS_SEARCH_RESPONSE";
export const HANDLE_ACCOUNTS_SEARCH_FAILURE =
  "HANDLE_ACCOUNTS_SEARCH_FAILURE";

export const HYDRATE_ACCOUNT_REQUEST = "HYDRATE_ACCOUNT_REQUEST";
export const HYDRATE_ACCOUNT_RESPONSE = "HYDRATE_ACCOUNT_RESPONSE";

export const SET_USER_ACCOUNT_COUNT = "SET_USER_ACCOUNT_COUNT";

export function updateAccountsFoundInSearch(newAccountResults) {
  return { type: UPDATE_ACCOUNTS_FOUND, newAccountResults };
}

export function updateChildAccountsFoundInSearch(newAccountResults) {
  return { type: UPDATE_CHILD_ACCOUNTS_FOUND_AND_SUMMARY, newAccountResults };
}

export function updateFilters(newFilters) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FILTERS, newFilters });
  };
}
export function updateSelectedAccount(newSelectedAccountObject) {
  return { type: UPDATE_ACCOUNTS_FOUND, newSelectedAccountObject };
}

export function updateFilteredAccountsFoundInSearch(searchResponse, isBaseGroup, resetCache) {
  return { type: UPDATE_ACCOUNTS_FOUND_AND_SUMMARY, searchResponse, isBaseGroup, resetCache };
}

export function updateChildAccountsFound(searchResponse) {
  return { type: UPDATE_ACCOUNTS_FOUND_AND_SUMMARY, searchResponse };
}

export function setUserAccountCount(accountCount, pmAccountCount, tenantAccountCount) {
  return { type: SET_USER_ACCOUNT_COUNT, accountCount, pmAccountCount, tenantAccountCount };
}

//filters: [{field:"name", target:""}],
function responseStillValid(responseFilters, stateFilters) {
  for (var x = 0; x < responseFilters.length; x++) {
    if (responseFilters[x] && stateFilters[x]) {
      if (
        responseFilters[x].field == stateFilters[x].field &&
        responseFilters[x].target == stateFilters[x].target
      ) {
        // Still valid
      }
    } else {
      return false;
    }
  }
  return true;
}

export function get(isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/accounts", {})
      .then((response) => {
        dispatch(updateAccountsFoundInSearch(response.data));
        dispatch({
          type: HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
          isLoading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

// Auth method to prevent accounts from being accesed around permissions.
// Makes a list of uuid's that consist of PM UuIds, Child PM Account Uuids, and Account Uuids
function getChildAccountsUuIdsAndUpdateLocalUserAuthCache(userChildAccounts, userUUID) {
  let currentUser = JSON.parse(window.localStorage.getItem('user'));
  let accountsCount = 0;
  let tenantAccountsCount = 0;
  let pmAccountsCount = 0;
  currentUser.access = [];// Resets the entire array
  if (currentUser && currentUser.uuid == userUUID) { //if the cached user uuid matches the targeted user uuid to update
    currentUser.access.push(currentUser.uuid)
    if (userChildAccounts.accounts && userChildAccounts.accounts.items.length > 0) {
      userChildAccounts.accounts.items.map(account => {
        currentUser.access.push(account.uuid);
        tenantAccountsCount++;
        accountsCount++;
      })
    }
    if (userChildAccounts.pmAccounts && userChildAccounts.pmAccounts.length > 0) {
      userChildAccounts.pmAccounts.map(pmAccount => {
        currentUser.access.push(pmAccount.uuid);
        accountsCount++;
        pmAccountsCount++;
      })
    }
    currentUser.accountsCount = accountsCount;
    currentUser.tenantAccountsCount = tenantAccountsCount;
    currentUser.pmAccountsCount = pmAccountsCount;
    // Saves the updated user in cache
    window.localStorage.setItem('user', JSON.stringify(currentUser))
  }
  setUserAccountCount(accountsCount, pmAccountsCount, tenantAccountsCount);
}

export function getAccountChildAccountsByUserUUID(userUUID, isLoading = true) {
  return dispatch => {
    dispatch({
      type: HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_REQUEST,
      isLoading: isLoading
    });
    return axios.get('/find-all-user-child-accounts/' + userUUID)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        }
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem('user'));
        if (currentUser) {
          if (response.data.accounts.items.length > 0 || response.data.pmAccounts.length > 0) {
            dispatch(updateChildAccountsFoundInSearch(response.data));
          }
          dispatch(getChildAccountsUuIdsAndUpdateLocalUserAuthCache(response.data, userUUID));
          dispatch({
            type: HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_RESPONSE,
            isLoading: false,
            error: null
          });
        }
        else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          })
        }
      })
      .catch(error => {
        dispatch({
          type: HANDLE_FINDS_CHILD_ACCOUNTS_SEARCH_FAILURE,
          isLoading: false,
          error: error
        })
      })
  }
}

// Actions
export function searchAccountsByFieldAndValue(
  filters,
  range,
  isLoading = true
) {
  // provide default values because api endpoint breaks otherwise
  if (!filters) filters = [{ field: "account", target: "" }];
  if (!range) range = { start: 0, end: 25 };
  return (dispatch) => {
    dispatch({
      type: HANDLE_ACCOUNTS_UPDATED_SEARCH_REQUEST,
      isLoading: isLoading,
    });
    const payload = { filters, range };
    return axios
      .post("/searchAccountByFieldAndValue", payload)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          if (responseStillValid(response.data.filters, filters)) {
            console.log("Is still valid response");
            dispatch(updateAccountsFoundInSearch(response.data.result));
          } else {
            console.log("Response no longer needed");
          }
          dispatch({
            type: HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function hydrateAccount(userUUID, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: HYDRATE_ACCOUNT_REQUEST,
      isLoading,
    });
    return axios
      .get("/account/hydrate/" + userUUID)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updateSelectedAccount(response.data));
          dispatch({
            type: HYDRATE_ACCOUNT_RESPONSE,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: HYDRATE_ACCOUNT_RESPONSE,
          error: error,
        });
      });
  };
}

export function searchForAccountsByFilters({ page, rowsPerPage, customerType, searchQuery, propertyUuid, accountStatusType, balanceStatus, pmUuid, userUuid, isLoading = true, isBaseGroup = true, resetCache = false, minAccountObj = false }) {
  // provide default values because api endpoint breaks otherwise
  var target = "?";
  if (page || page == 0) { target = target + "page=" + page + "&"; }
  if (rowsPerPage && rowsPerPage != null) { target = target + "pageLimit=" + rowsPerPage + "&"; }
  if (customerType && customerType != null) { target = target + "accountType=" + customerType + "&"; }
  if (searchQuery && searchQuery != "") { target = target + "searchQuery=" + encodeURIComponent(searchQuery) + "&"; }
  if (propertyUuid && propertyUuid != null) { target = target + "propertyUuid=" + propertyUuid + "&"; }
  if (accountStatusType && accountStatusType != null) { target = target + "status=" + accountStatusType + "&"; }
  if (balanceStatus && balanceStatus != null) { target = target + "balanceStatus=" + balanceStatus + "&"; }
  if (pmUuid && pmUuid != null) { target = target + "pmUuid=" + pmUuid + "&"; }
  if (userUuid && userUuid != null) { target = target + "userUuid=" + userUuid + "&"; }
  target = target + "minimumAccountObject=" + minAccountObj + "&";
  return (dispatch) => {
    dispatch({
      type: HANDLE_ACCOUNTS_SEARCH_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/get-accounts-filtered/" + target)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(updateFilteredAccountsFoundInSearch(response.data, isBaseGroup, resetCache));
          dispatch({
            type: HANDLE_ACCOUNTS_SEARCH_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: HANDLE_ACCOUNTS_UPDATED_SEARCH_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: HANDLE_ACCOUNTS_UPDATED_SEARCH_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}
