import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

// App Imports
import { setUserAndToken, loginSetUserLocalStorageAndCookie } from './modules/user/api/actions'
import { store } from './setup/store'


// User Authentication: This checks storage on the current tokens in the browser and reloads that into the current state.
const token = window.localStorage.getItem('token')
if (token && token !== 'undefined' && token !== '') {
  const user = JSON.parse(window.localStorage.getItem('user'));
  if (user) {
    // Dispatch action
    store.dispatch(setUserAndToken(token, user))
    loginSetUserLocalStorageAndCookie(token, user)
  }
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();