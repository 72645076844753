import {
  GET_WORK_ORDERS_FAILURE,
  GET_WORK_ORDERS_REQUEST,
  GET_WORK_ORDERS_RESPONSE,
  CREATE_WORK_ORDER_FAILURE,
  CREATE_WORK_ORDER_REQUEST,
  CREATE_WORK_ORDER_RESPONSE,
  UPDATE_WORK_ORDERS_FAILURE,
  UPDATE_WORK_ORDERS_REQUEST,
  UPDATE_WORK_ORDERS_RESPONSE,
  GET_UNIT_WORK_ORDERS_REQUEST,
  GET_UNIT_WORK_ORDERS_RESPONSE,
  GET_UNIT_WORK_ORDERS_FAILURE,
  GET_PROPERTY_WORK_ORDERS_REQUEST,
  GET_PROPERTY_WORK_ORDERS_RESPONSE,
  GET_PROPERTY_WORK_ORDERS_FAILURE
} from "./actions";
const today = new Date();
// Initial State
const workOrderInitialState = {
  isLoading: false,
  error: null,
  data: {
    workOrders: { items: [], toDelete: [], toUpdate: [] },
  },
  items: [],
  propertyWorkOrders: { items: [], toDelete: [], toUpdate: [] },
  unitWorkOrders: { items: [], toDelete: [], toUpdate: [] },
  workOrders: { items: [], toDelete: [], toUpdate: [] },
};

// State
export default (state = workOrderInitialState, action) => {
  switch (action.type) {
    case UPDATE_WORK_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_UNIT_WORK_ORDERS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_UNIT_WORK_ORDERS_RESPONSE:
      var newUnitWorkOrders = { items: [], toDelete: [], toUpdate: [] }
      if (action.response) {
        newUnitWorkOrders.items = action.response;
      }
      return {
        ...state,
        unitWorkOrders: newUnitWorkOrders,
        error: null,
        isLoading: false,
      };
    case GET_UNIT_WORK_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_PROPERTY_WORK_ORDERS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_PROPERTY_WORK_ORDERS_RESPONSE:
      return {
        ...state,
        propertyWorkOrders: {
          items: action.response, toDelete: [], toUpdate: []
        },
        error: null,
        isLoading: false,
      };
    case GET_PROPERTY_WORK_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_WORK_ORDERS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case GET_WORK_ORDERS_RESPONSE:
      return {
        ...state,
        workOrders: {
          items: action.response,
        },
        error: null,
        isLoading: false,
      };
    case GET_WORK_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case UPDATE_WORK_ORDERS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case UPDATE_WORK_ORDERS_RESPONSE:
      return {
        ...state,
        workOrders: {
          items: state.workOrders.items.map((setting) => {
            if (setting.uuid == action.response.uuid) {
              return action.response;
            }
            return setting;

          })
        },
        error: null,
        isLoading: false,
        updateSuccess: true,
      };
    case UPDATE_WORK_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case CREATE_WORK_ORDER_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: action.isLoading,
      };
    case CREATE_WORK_ORDER_RESPONSE:
      return {
        ...state,
        workOrders: {
          items: state.workOrders.items.concat(action.response)
        },
        error: null,
        isLoading: false,
        updateSuccess: true,
      };
    case CREATE_WORK_ORDER_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
};
