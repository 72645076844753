// Imports
import axios from 'axios'
import cookie from 'js-cookie'


// App Imports
require('../../../setup/config/apiConfig');

// Actions Types
export const DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_REQUEST = 'DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_REQUEST'
export const DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_RESPONSE = 'DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_RESPONSE'
export const DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_FAILURE = 'DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_FAILURE'
export const DELINQUENCY_ACCOUNT_DELINQUENCYS_RESET = 'DELINQUENCY_ACCOUNT_DELINQUENCYS_RESET'
export const SET_DELINQUENCY_LIST = 'DELINQUENCY/SET_DELINQUENCY_LIST'

export const DELINQUENCY_CREAT_NEW_ACCOUNT_DELINQUENCY_REQUEST = 'DELINQUENCY_CREAT_NEW_ACCOUNT_DELINQUENCY_REQUEST'
export const DELINQUENCY_CREAT_NEW_ACCOUNT_DELINQUENCY_RESPONSE = 'DELINQUENCY_CREAT_NEW_ACCOUNT_DELINQUENCY_RESPONSE'

export const DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_REQUEST = 'DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_REQUEST'
export const DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_RESPONSE = 'DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_RESPONSE'
export const SET_FILTERED_GROUPED_DELINQUENCIES_LIST = 'SET_FILTERED_GROUPED_DELINQUENCIES_LIST'


export const DELINQUENCY_UPDATE_NEW_ACCOUNT_DELINQUENCY_IN_PROGRESS = 'DELINQUENCY_UPDATE_NEW_ACCOUNT_DELINQUENCY_IN_PROGRESS'

// Actions
export function setDelinquencies(data) {
  return { type: SET_DELINQUENCY_LIST, data }
}
export function updateNewDelinquencyItemInProgress(newDelinquencyData) {
  return { type: DELINQUENCY_UPDATE_NEW_ACCOUNT_DELINQUENCY_IN_PROGRESS, newDelinquencyData }
}

export function setGroupedFilteredDelinquencies(data, clearCache, isBaseGroup) {
  return { type: SET_FILTERED_GROUPED_DELINQUENCIES_LIST, data, clearCache, isBaseGroup }
}

// Login a user using credentials
export function getAccountDelinquenciesByAccountID(accountUUID, isLoading = true) {
  return dispatch => {
    dispatch({
      type: DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_REQUEST,
      isLoading: isLoading
    });
    return axios.get('/accountdelinquent/account/'+ accountUUID)
      .then(response => {
        let error = '';
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
        } 
        
        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem('user'))
        if (currentUser) {
          //Updating the delinquencies obj in state
          dispatch({
            type: DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_RESPONSE,
            isLoading: false,
            error: error,
            data: response.data
          });
        }
        else{
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          })
        }
      })
      .catch(error => {
        dispatch({
          type: DELINQUENCY_GET_ACCOUNT_DELINQUENCYS_FAILURE,
          isLoading: false,
          error: error
        })
      })
  }
}

export function createNewAccountDelinquency(newAccountDelinquency, isLoading = true) {
  return dispatch => {
    dispatch({
      type: DELINQUENCY_CREAT_NEW_ACCOUNT_DELINQUENCY_REQUEST,
      isLoading
    });
    return axios.post('/accountdelinquent', newAccountDelinquency)
      .then(response => {
      let error = '';
      if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message
      }
      // Update/Check if user object is the obj state
      const currentUser = JSON.parse(window.localStorage.getItem('user'))
      if (currentUser) {
        dispatch({
          type: DELINQUENCY_CREAT_NEW_ACCOUNT_DELINQUENCY_RESPONSE,
          error: error
        })
      }
      else{
        // if no user is found then clear object because its an unauthorized access
        dispatch({
          type: DELINQUENCY_CREAT_NEW_ACCOUNT_DELINQUENCY_RESPONSE,
          isLoading: false,
          error: "ERROR! NO USER LOGGED IN!",
        })
      }
      })
      .catch(error => {
        //console.log(error);
        dispatch({
          type: DELINQUENCY_CREAT_NEW_ACCOUNT_DELINQUENCY_RESPONSE,
          error: error
        })
      })
  }
}


export function searchForDelinquencyGroupsByFilters({page, rowsPerPage, customerType, searchQuery, propertyUuid, accountStatusType, pmUuid, isLoading = true, isBaseGroup=false, resetCache=false}) {
  // provide default values because api endpoint breaks otherwise
  var target = "?";
  if (page || page == 0){ target=target+"page="+page+"&"; }
  if (rowsPerPage && rowsPerPage != null || rowsPerPage == 0){ target=target+"pageLimit="+rowsPerPage+"&"; }
  if (customerType && customerType != null){ target=target+"accountType="+customerType+"&"; }
  if (searchQuery && searchQuery != ""){ target=target+"searchQuery="+encodeURIComponent(searchQuery)+"&"; }
  if (propertyUuid && propertyUuid != null){ target=target+"propertyUuid="+propertyUuid+"&"; }
  if (accountStatusType && accountStatusType != null){ target=target+"accountStatus="+accountStatusType+"&"; }
  if (pmUuid && pmUuid != null){ target=target+"pmUuid="+pmUuid+"&"; }
  return (dispatch) => {
    dispatch({
      type: DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/accounts-delinquency-group-filtered/" + target)
      .then((response) => {
        let error = "";
        if (response.data.errors && response.data.errors.length > 0) {
          error = response.data.errors[0].message;
        }

        // Update/Check if user object is the obj state
        const currentUser = JSON.parse(window.localStorage.getItem("user"));
        if (currentUser) {
          dispatch(setGroupedFilteredDelinquencies(response.data, resetCache, isBaseGroup));
          dispatch({
            type: DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_RESPONSE,
            isLoading: false,
            error: error,
          });
        } else {
          // if no user is found then clear object because its an unauthorized access
          dispatch({
            type: DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_RESPONSE,
            isLoading: false,
            error: "ERROR! NO USER LOGGED IN!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELINQUENCY_GET_FILTERED_GROUPED_DELINQUENCIES_RESPONSE,
          isLoading: false,
          error: error,
        });
      });
  };
}