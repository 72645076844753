// Imports
import axios from "axios";
require("../../../setup/config/apiConfig");

export const RESET = "RESET";
export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_RESPONSE = "SEARCH_RESPONSE";
export const SEARCH_FAILURE = "SEARCH_FAILURE";

export const GET_UNITS = "GET_UNITS";
export const GET_UNITS_RESPONSE = "GET_UNITS_RESPONSE";
export const GET_UNITS_FAIL = "GET_UNITS_RESPONSE_FAIL";

export const GET_UNIT = "GET_UNIT";
export const GET_UNIT_RESPONSE = "GET_UNIT_RESPONSE";
export const GET_UNIT_FAIL = "GET_UNIT_FAIL";

export const POST_UNIT = "POST_UNIT";
export const POST_UNIT_RESPONSE = "POST_UNIT_RESPONSE";
export const POST_UNIT_FAIL = "POST_UNIT_FAIL";

export const PUT_UNIT = "PUT_UNIT";
export const PUT_UNIT_RESPONSE = "PUT_UNIT_RESPONSE";
export const PUT_UNIT_FAIL = "PUT_UNIT_FAIL";

export function get(searchQuery, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_REQUEST,
      isLoading: isLoading,
    });
    return axios
      .get("/unit", { params: null })
      .then((response) => {
        //if (validateResponse(response.data.filters, filters)) dispatch(updateSearchResults(response.data.result));
        dispatch({
          type: SEARCH_RESPONSE,
          units: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByPropertyId(propertyId, searchQuery, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_REQUEST,
      isLoading: isLoading,
    });
    let queryParams =
      searchQuery && searchQuery.length > 0
        ? {
            unitType: searchQuery,
            ownerName: searchQuery,
            address: searchQuery,
          }
        : {};
    return axios
      .get(`/property/${propertyId}/unit`, { params: queryParams })
      .then((response) => {
        //if (validateResponse(response.data.filters, filters)) dispatch(updateSearchResults(response.data.result));
        dispatch({
          type: SEARCH_RESPONSE,
          units: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_FAILURE,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function getByUuid(uuid, isLoading = true) {
  return (dispatch) => {
    dispatch({
      type: GET_UNIT,
      isLoading: isLoading,
    });
    return axios
      .get(`/unit/${uuid}`, {})
      .then((response) => {
        dispatch({
          type: GET_UNIT_RESPONSE,
          unit: response.data[0],
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_UNIT_FAIL,
          isLoading: false,
          error: error,
        });
      });
  };
}

export function post(u, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: POST_UNIT,
      isLoading,
    });
    return axios
      .post("/unit/", u)
      .then((response) => {
        dispatch({
          unit: response.data,
          type: POST_UNIT_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: POST_UNIT_FAIL,
          error: error,
        });
      });
  };
}

export function put(u, isLoading = false) {
  return (dispatch) => {
    dispatch({
      type: PUT_UNIT,
      isLoading,
    });
    return axios
      .put("/unit/" + u.uuid, u)
      .then((response) => {
        dispatch({
          unit: response.data,
          type: PUT_UNIT_RESPONSE,
        });
      })
      .catch((error) => {
        //console.log(error);
        dispatch({
          type: PUT_UNIT_FAIL,
          error: error,
        });
      });
  };
}
